import React, { useEffect, useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { H3Title } from 'components/H3Title';
import { useInView } from 'react-intersection-observer';

export interface Text_ImageProps {
  title: string;
  text: JSX.Element;
}

export function SyntheseStructuree({ title, text }: Text_ImageProps): JSX.Element {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0,
  });

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-col lg:flex-row', 'p-3 mx-10 py-12', 'gap-4')}>
          <div className={concatClassNames('flex items-center w-full justify-center')}>
            <div className="h-80 w-80 rounded-full bg-blue-200 relative">
              <div
                ref={ref1}
                className={concatClassNames(
                  'absolute -top-6 -right-10 w-[250px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? 'lg:translate-x-10' : 'opacity-0 translate-x-20 lg:-translate-x-10 '
                  }`,
                )}
              >
                <img src="/imgs/agence_bleu.png" alt={title} />
              </div>
              <div
                ref={ref2}
                className={concatClassNames(
                  'absolute top-28 left-32 w-[200px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView2 ? 'lg:-translate-x-44' : 'opacity-0 translate-x-20 lg:translate-x-44 '
                  }`,
                )}
              >
                <img src="/imgs/mon_besoin.png" alt={title} />
              </div>
              <div ref={ref3} className={concatClassNames('absolute top-20 left-28 w-[150px]')}>
                <img src="/imgs/avion_papier_bleu.png" alt={title} />
              </div>
            </div>
          </div>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full px-24', 'items-center')}>
            <H3Title text={title} position="left" color="black" />
            <div className={concatClassNames('flex flex-col', 'p-3', 'gap-4 text-justify')}>{text}</div>
          </div>
        </div>
      ) : (
        <div className={concatClassNames('flex flex-col lg:flex-row', 'p-3 mx-10 py-12', 'gap-4')}>
          <div className={concatClassNames('flex items-center w-full justify-center')}>
            <div className="h-60 w-60 rounded-full bg-blue-200 relative">
              <div
                ref={ref1}
                className={concatClassNames(
                  'absolute -top-10 -right-10 w-[200px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? 'translate-y-10' : 'opacity-0 -translate-y-10  '
                  }`,
                )}
              >
                <img src="/imgs/agence_bleu.png" alt={title} />
              </div>
              <div
                ref={ref2}
                className={concatClassNames(
                  'absolute top-28 left-0 w-[150px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView2 ? '-translate-y-10' : 'opacity-0 translate-y-10  '
                  }`,
                )}
              >
                <img src="/imgs/mon_besoin.png" alt={title} />
              </div>
              <div ref={ref3} className={concatClassNames('absolute top-20 left-16 w-[120px]')}>
                <img src="/imgs/avion_papier_bleu.png" alt={title} />
              </div>
            </div>
          </div>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full ', 'items-center')}>
            <H3Title text={title} position="center" color="black" />
            <div className={concatClassNames('flex flex-col', 'p-3', 'gap-4 text-justify')}>{text}</div>
          </div>
        </div>
      )}
    </>
  );
}
