// types

import { type TextColor } from '../Text/Text';

type CardWidth = 'xs' | 'sm' | 'smd' | 'md' | 'lg' | 'xl' | '2xl' | 'full' | 'fit';

export const cardWidthStyle: Record<CardWidth, string> = {
  xs: 'w-24',
  sm: 'w-32',
  smd: 'w-60',
  md: 'w-80',
  lg: 'w-[22rem]',
  xl: 'w-[24rem]',
  '2xl': 'w-[30rem]',
  full: 'w-full',
  fit: 'w-fit',
};

type CardHeight = 'xs' | 'sm' | 'md' | 'lg' | 'full' | 'fit' | 'smd' | 'xl' | 'xxl' | '3xl';

export const cardHeightStyle: Record<CardHeight, string> = {
  xs: 'h-24',
  sm: 'h-32',
  smd: 'h-60',

  md: 'h-80',
  lg: 'h-[22rem]',
  xl: 'h-96',
  xxl: 'h-[26rem]',
  '3xl': 'h-[44rem]',

  full: 'h-full',
  fit: 'h-fit',
};

type CardShadow = 'none' | 'sm' | 'md' | 'lg';

export const cardShadowStyle: Record<CardShadow, string> = {
  none: 'shadow-none',
  sm: 'shadow-sm',
  md: 'shadow-md',
  lg: 'shadow-lg',
};

type CardHeaderHeight = 'sm' | 'md' | 'fill';

export const cardHeaderHeightStyle: Record<CardHeaderHeight, string> = {
  sm: 'h-10',
  md: 'h-12',
  fill: 'h-fill',
};

type CardBodyHeight = 'sm' | 'md' | 'lg' | 'fill';

export const cardBodyHeightStyle: Record<CardBodyHeight, string> = {
  sm: 'h-12',
  md: 'h-24',
  lg: 'h-48',
  fill: 'h-fill',
};

export type CardBackgroundColor =
  | 'white'
  | 'veryLightGray'
  | 'lightGray'
  | 'gray'
  | 'purple'
  | 'gradient1'
  | 'blue-400'
  | 'blue-100';

export const cardBackgroundColorStyle: Record<CardBackgroundColor, string> = {
  white: 'bg-white',
  veryLightGray: 'bg-gray-15/50',
  lightGray: 'bg-gray-15',
  gray: 'bg-gray-25',
  purple: 'bg-purple-900/20',
  gradient1: 'bg-gradient-to-r from-gradient1-from to-gradient1-to',
  'blue-400': 'bg-blue-400',
  'blue-100': 'bg-blue-100',
};

export type CardBorderColor = 'lightGray' | 'gray' | 'gradient2' | 'blue-400';

export const cardBorderColorStyle: Record<CardBorderColor, string> = {
  lightGray: 'border-gray-25',
  gray: 'border-gray-50',
  gradient2: 'border-gradient2-to',
  'blue-400': 'border-blue-400',
};

type CardOrientation = 'horizontal' | 'vertical';

export const cardOrientationStyle: Record<CardOrientation, string> = {
  horizontal: 'flex-row',
  vertical: 'flex-col',
};

type CardVerticalPadding = 'none' | 'sm' | 'md' | 'lg';

export const cardVerticalPaddingStyle: Record<CardVerticalPadding, string> = {
  none: 'py-0',
  sm: 'py-2',
  md: 'py-4',
  lg: 'py-6',
};

type CardHorizontalPadding = 'none' | 'sm' | 'md' | 'lg';

export const cardHorizontalPaddingStyle: Record<CardHorizontalPadding, string> = {
  none: 'px-0',
  sm: 'px-2',
  md: 'px-4',
  lg: 'px-6',
};

type CardRound = 'md' | '3xl';

export const cardRoundStyle: Record<CardRound, string> = {
  md: 'rounded-md',
  '3xl': 'rounded-3xl',
};

type CardMargin = 'none' | 'sm' | 'md' | 'lg';

export const cardMarginStyle: Record<CardMargin, string> = {
  none: 'm-0',
  sm: 'm-2',
  md: 'm-4',
  lg: 'm-6',
};

type CardItems = 'start' | 'center' | 'end';

export const cardItemsStyle: Record<CardItems, string> = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
};

type CardGap = 'none' | 'sm' | 'md' | 'lg';

export const cardGapStyle: Record<CardGap, string> = {
  none: 'gap-0',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-6',
};

type CardJustify = 'start' | 'center' | 'end';

export const cardJustifyStyle: Record<CardJustify, string> = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
};

type CardVerticalMargin = 'none' | 'sm' | 'md' | 'lg' | 'auto';

export const cardVerticalMarginStyle: Record<CardVerticalMargin, string> = {
  none: 'my-0',
  sm: 'my-2',
  md: 'my-4',
  lg: 'my-6',
  auto: 'my-auto',
};

export type CardHorizontalMargin = 'none' | 'sm' | 'md' | 'lg' | 'auto';

export const cardHorizontalMarginStyle: Record<CardHorizontalMargin, string> = {
  none: 'mx-0',
  sm: 'mx-2',
  md: 'mx-4',
  lg: 'mx-6',
  auto: 'mx-auto',
};

type CardDeepness = 'none' | '0' | '1';

export const cardDeepnessStyle: Record<CardDeepness, string> = {
  none: '',
  0: 'z-0',
  1: 'z-10',
};

// props

export interface CardProps {
  width?: CardWidth;
  height?: CardHeight;
  shadow?: CardShadow;
  background?: CardBackgroundColor;
  borderColor?: CardBorderColor;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
  loading?: boolean;
  customRef?: React.Ref<HTMLDivElement>;
  orientation?: CardOrientation;
  verticalPadding?: CardVerticalPadding;
  horizontalPadding?: CardHorizontalPadding;
  round?: CardRound;
  vertialMargin?: CardVerticalMargin;
  horizontalMargin?: CardHorizontalMargin;
  deepness?: CardDeepness;
  disabled?: boolean;
  hasAnimation?: boolean;
  textColor?: TextColor;
  position?: string;
  info?: string;
}

export type CardHeaderProps =
  | {
      children: JSX.Element;
      height?: CardHeaderHeight;
      bgColor: string;
    }
  | {
      children?: JSX.Element;
      height?: CardHeaderHeight;
      bgColor?: string;
    };

export interface CardBodyProps {
  children: JSX.Element | JSX.Element[];
  height?: CardBodyHeight;
  margin?: CardMargin;
  items?: CardItems;
  verticalPadding?: CardVerticalPadding;
  horizontalPadding?: CardHorizontalPadding;
  gap?: CardGap;
  orientation?: CardOrientation;
  justify?: CardJustify;
}
