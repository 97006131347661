import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Language } from 'utils/languages';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { PreInscriptionForm } from 'components/Sections/PreInscriptionForm';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { H4Title } from 'components/H4Title';
import { SmallP } from 'components/smallP';

const language: Language = Language.FR;

export function DisckoContactPage(): JSX.Element {
  const submittedMessage: JSX.Element = (
    <>
      <P text="Merci, nous avons bien reçu vos coordonnées!" color="white" position="center" weight="bold" />
      <P text="Laureline ou Romain revient vers vous sous 24H." color="white" position="center" weight="medium" />
    </>
  );
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 lg:gap-4')}
    >
      <DisckoHeader language={language} />

      <div className={concatClassNames('flex flex-col gap-12')}>
        <div
          className={concatClassNames(
            'row-span-1 lg:col-span-3',
            'flex flex-col gap-8 px-6 justify-center align-middle',
            'h-full w-full',
          )}
        >
          <div className="flex flex-col items-center">
            <img src="/imgs/disco-ball.gif" className="w-24 h-24" />
            <H2Title text="Notre équipe est à votre service" position="center" color="black" />
          </div>
          <H4Title
            text="Vous souhaitez nous contacter pour une démo, un partenariat ou toute autre question ? "
            position="center"
            color="black"
          />
          <H4Title
            text="Remplissez le formulaire et Laureline ou Romain reviendra vers vous sous 24h!"
            position="center"
            color="black"
          />

          <P
            text="PS: si vous hésitez à remplir... vous avez compris l'intérêt de Discko! 😉 "
            position="center"
            color="black"
          />
        </div>
        <div className={concatClassNames(' flex justify-center py-12 bg-blue-400', 'w-full')}>
          <div className="lg:w-[50%] w-[80%]">
            <PreInscriptionForm submitedMessage={submittedMessage} type="discko" />
          </div>
        </div>
      </div>
      <Footer language={language} />
    </div>
  );
}
