import React, { useRef, useState } from 'react';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { useInView } from 'react-intersection-observer';

export function HomePagePicture(): JSX.Element {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    threshold: 0,
  });
  const { ref: ref5, inView: inView5 } = useInView({
    threshold: 0,
  });

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <div className="flex w-[30%] items-center mr-36">
      <div className="h-80 w-80 rounded-full bg-blue-200 relative">
        <div
          ref={ref1}
          className={concatClassNames(
            'absolute -top-10 -left-10 w-[200px] z-10',
            `transition-all ease-in-out duration-700 delay-200 ${
              inView1 ? 'lg: translate-x-20' : 'opacity-0 translate-x-20 lg:-translate-x-20 '
            }`,
          )}
        >
          <img src="/imgs/cover_client.png" />
        </div>
        <div
          ref={ref2}
          className={concatClassNames(
            'absolute -bottom-20 -right-28 w-[200px] z-10',
            `transition-all ease-in-out duration-700 delay-200 ${
              inView2 ? 'lg:-translate-x-10' : 'opacity-0 translate-x-10 lg:translate-x-10 '
            }`,
          )}
        >
          <img src="/imgs/cover_agence.png" />
        </div>
        <div
          ref={ref3}
          className={concatClassNames(
            'absolute top-20 -right-10 w-[200px]',
            `transition-all ease-in-out duration-700 delay-300 ${
              inView3 ? 'lg:-translate-y-20' : 'opacity-0 translate-x-20 lg:translate-y-20 '
            }`,
          )}
        >
          <img src="/imgs/cover_wireframe.png" />
        </div>
        <div
          ref={ref4}
          className={concatClassNames(
            'absolute top-20 left-10 w-[220px]',
            `transition-all ease-in-out duration-700 delay-300 ${
              inView4 ? 'lg:-translate-x-20' : 'opacity-0 translate-x-20 lg:translate-x-40 '
            }`,
          )}
        >
          <img src="/imgs/cover_question.png" />
        </div>
        <div
          ref={ref5}
          className={concatClassNames(
            'absolute top-0 right-0 w-[80px]',
            `transition-all ease-in-out duration-700 delay-700 ${
              inView5 ? 'lg:translate-x-20' : 'opacity-0 translate-x-10 lg:-translate-x-20 '
            }`,
          )}
        >
          <img src="/imgs/cover_ai.png" />
        </div>
      </div>
    </div>
  );
}
