import { Button } from 'components/Button/Button';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import concatClassNames from 'utils/classNames';

interface ValueParagraph {
  title: string;
  content: string;
  feature: string;
  cta?: string;
  url?: string;
}
export function DisckoTabs(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const valueParagraph: ValueParagraph[] = [
    {
      title: 'Des clients mieux préparés',
      content:
        "Avec Discko, engagez vos clients de manière à ce qu'ils réfléchissent aux aspects essentiels de leur projet avant de vous rencontrer. Cela assure un recueil du besoin efficace lors du premier rendez-vous. ",
      feature:
        '✨ Réduisez de manière significative la durée de vos réunions de cadrage tout en augmentant la qualité de vos échanges.',
      cta: 'En savoir +',
      url: '/agences/#accompagnement_sur_mesure',
    },
    {
      title: 'Des briefs complets & synthétiques',
      content:
        'En quelques lignes, Discko vous aide à identifier le niveau de maturité de vos clients par rapport à leur projet et à personnaliser votre accompagnement. ',
      feature: '✨ Investissez votre énergie au bon endroit et augmentez jusqu’à 40% votre ROI.',
      cta: 'En savoir +',
      url: '/agences/#briefs_complets',
    },
    {
      title: 'Un processus de vente optimisé',
      content:
        'Discko vous aide à transformer les prospects en clients convaincus en améliorant la compréhension de leurs besoins dès le départ, augmentant ainsi votre taux de conversion.',
      feature: '',
      cta: 'En savoir +',
      url: '/agences/#conversion_client',
    },
    {
      title: 'Une IA au service de votre image',
      content:
        'En adoptant Discko, montrez que votre agence est à la pointe de la technologie, utilisant des outils innovants qui placent toujours les intérêts du client au premier plan. ',
      feature: '✨Mettez votre caractère innovant au coeur de votre réputation et image d’agence digitale.',
      url: '/agences/#intelligence_artificielle',
      cta: 'En savoir +',
    },
  ];
  return (
    <div
      className={concatClassNames(
        'flex lg:flex-row flex-col gap-12',
        'lg:px-20 lg:my-20 pt-20 pb-10 px-10',
        'lg:h-[500px] xl:h-[600px] lg:overflow-auto lg:relative lg:no-scrollbar ',
      )}
    >
      <div className="lg:w-[50%] lg:sticky flex -top-20 left-0 items-center justify-center">
        <img src="/imgs/flux.png" className="lg:w-[65%] " />
      </div>
      <div className={concatClassNames('flex flex-col gap-24 lg:gap-24 lg:w-[50%]')}>
        {valueParagraph.map((item, index) => {
          return (
            <div key={index} className="flex flex-col gap-4">
              <H3Title position="left" text={item.title} />
              <P position="justify" text={item.content} />
              <P position="left" weight="bold" text={item.feature} />
              {item.cta && item.url && (
                <Button
                  href={item.url}
                  text={item.cta}
                  backgroundColor="blue-400"
                  textColor="white"
                  horizontalPadding="2"
                  verticalPadding="1"
                  size="p"
                  openInNewTab={false}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
