import React from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';

export interface DisckoCTAProps {
  language: Language;
  text: string;
  button: string;
  textColor?: TextColor;
}

// ALEX : bouton onclick
export function DisckoCTA({
  language = Language.FR,
  text,
  button,
  textColor = 'blue-400',
}: DisckoCTAProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col',
        'text-black text-title',
        'text-center',
        'items-center',
        'font-semibold',
        'lg:pb-20',
        'p-3',
        'py-20',
        'gap-14 lg:gap-10',
        'lg:text-6xl',
      )}
    >
      <H2Title text={text} position="center" color={textColor} />
      <div className={concatClassNames('flex', 'justify-end lg:font-circularStd-Regular')}>
        <Button
          backgroundColor="blue-400"
          textColor="white"
          text={button}
          verticalPadding="2"
          horizontalPadding="4"
          href="/discko"
          onHover="hover:bg-blue-300 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
        />
      </div>
    </div>
  );
}
