import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import { DisckoHomePage } from 'pages/Discko/DisckoHomePage';
import { PricingPage } from 'pages/Pricing/PricingPage';
import { DisckoTestPage } from 'pages/Discko/DisckoTestPage';
import { DisckoForAgencies } from 'pages/Discko for.../Agencies';
import { DisckoForClients } from 'pages/Discko for.../Clients';
import { DisckoContactPage } from 'pages/Discko/Contact';
import { TermsPage } from 'pages/Legal/TermsPage';
import { ScrollToAnchor, ScrollToTop } from 'utils/ScrollTo';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <ScrollToAnchor />
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<DisckoHomePage />} />
          <Route path="/discko" element={<DisckoTestPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contact" element={<DisckoContactPage />} />
          <Route path="/agences" element={<DisckoForAgencies />} />
          <Route path="/clients" element={<DisckoForClients />} />

          {/* <Route path="/features/mapping" element={<MappingPage />} />
          <Route path="/features/dependencies" element={<DependenciesPage />} />
          <Route path="/features/informations" element={<InformationsPage />} />
          <Route path="/features/business-rules" element={<BusinessRulesPage />} /> */}
          {/* <Route path="/contact" element={<ContactPage />} /> */}
          <Route path="/terms" element={<TermsPage />} />
        </Routes>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
