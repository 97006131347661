import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Button } from 'components/Button/Button';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useState } from 'react';
import { set } from 'react-hook-form';
import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { type Audience, type Language } from 'utils/languages';

interface TrialProcessProps {
  language: Language;
  audience: Audience;
}
export function TestSection(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  if (window.innerWidth > 1450) {
    return (
      <div className={concatClassNames('flex flex-row w-full justify-center')}>
        <iframe
          src="https://app.discko.io/new/?companyid=4d039830-bb1f-4a8b-8de0-509b3a9315c4"
          width="80%"
          className="rounded-lg aspect-video"
        />
      </div>
    );
  }

  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-row w-full justify-center', 'scale-[0.8]')}>
          <iframe
            src="https://app.discko.io/new/?companyid=4d039830-bb1f-4a8b-8de0-509b3a9315c4"
            width="90%"
            className="rounded-lg aspect-video"
          />
        </div>
      ) : (
        <div className={concatClassNames('flex flex-row justify-center', 'aspect-[9/16]', 'scale-90', 'w-full')}>
          <iframe
            src="https://app.discko.io/new/?companyid=4d039830-bb1f-4a8b-8de0-509b3a9315c4"
            className={concatClassNames('rounded-lg w-full h-full')}
          />
        </div>
      )}
    </>
  );
}
