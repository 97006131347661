import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { type IconName } from 'icons/Icon.props';
import PlusIcon from 'icons/PlusIcon';
import React, { useState } from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

type FaqCollapseProps =
  | {
      id: string;
      title: string;
      content: string;
      loading?: false;
    }
  | {
      id?: never;
      title?: never;
      content?: never;
      loading: true;
    };

export function FaqCollapse({ id = '', title = '', content = '', loading = false }: FaqCollapseProps): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleOnClick(): void {
    setIsCollapsed(!isCollapsed);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-col', 'w-full', 'p-3', isCollapsed ? 'gap-4' : '')}>
          <div
            className={concatClassNames(
              'flex flex-row',
              'w-full',
              'justify-between gap-8',
              'items-center',
              'cursor-pointer',
            )}
            onClick={handleOnClick}
          >
            <P text={title} color="black" weight="bold" position="left" />
            <div className="flex flex-row gap-5 items-center">{getIcon('plus', 'black', 'md')}</div>
          </div>
          <div className={concatClassNames('flex flex-row', 'w-fit text-justify', isCollapsed ? '' : 'invisible')}>
            <P
              text={content}
              color="black"
              weight="light"
              position="left"
              hidden={!isCollapsed}
              textBreak="break-words"
              style="text-justify"
            />
          </div>
        </div>
      ) : (
        <div className={concatClassNames('flex flex-col', 'w-full', 'p-3', isCollapsed ? 'gap-4' : '')}>
          <div
            className={concatClassNames(
              'flex flex-row',
              'w-full',
              'justify-between gap-8',
              'items-center',
              'cursor-pointer',
            )}
            onClick={handleOnClick}
          >
            <P text={title} color="black" weight="bold" position="left" />
            <div className="flex flex-row gap-5 items-center">{getIcon('plus', 'black', 'md')}</div>
          </div>
          <div className={concatClassNames('flex flex-row', 'w-fit text-justify', isCollapsed ? '' : 'invisible')}>
            <P
              text={content}
              color="black"
              weight="light"
              position="left"
              hidden={!isCollapsed}
              textBreak="break-words"
              style="text-justify"
            />
          </div>
        </div>
      )}
    </>
  );
}
