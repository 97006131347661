import { FaqCollapse } from 'components/FaqCollapse/FaqCollapse';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import React, { useState } from 'react';
import uuid from 'react-uuid';
export function DisckoFaq(): JSX.Element {
  interface FAQ {
    question: string;
    answer: string;
  }
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const faqData: FAQ[] = [
    {
      question: 'Quels types de projets peuvent être décrits avec Discko ?',
      answer: `Discko est conçu pour être flexible et adaptable à l’ensemble des projets digitaux. Qu'il s'agisse de développement web, de design UX/UI, de webmarketing, ou de toute autre besoin, Discko peut s'ajuster pour collecter des informations pertinentes pour chacun de ces types de projet.`,
    },
    {
      question: 'Quels types de questions sont posées par Discko ?',
      answer: `Discko pose des questions personnalisées en fonction des besoins exprimés par le prospect. Ces questions sur-mesure sont générées en fonction des réponses apportées précédemment par le prospect. Elles couvrent divers aspects tels que les objectifs du projet, les utilisateurs cibles, les facteurs de succès, ainsi que les estimations de budget et les délais souhaités. Cela permet de recueillir des informations précieuses sur le projet du client, afin de vous préparer efficacement pour un premier rendez-vous.`,
    },
    {
      question: 'Combien de temps un prospect met-il en moyenne pour remplir un formulaire Discko ?',
      answer:
        'Toutes les questions générées par Discko ne sont pas obligatoires. En moyenne, un prospect met moins de 3 minutes pour obtenir sa synthèse avec Discko.',
    },
    {
      question: 'Discko est-il personnalisable pour notre agence ?',
      answer: `Oui, notre formule premium propose la personnalisation de Discko.  Vous pouvez décider d'intégrer ou non les questions initiales (budget, délais) et de l'orientation des questions. En formule entreprise, pouvez choisir la police et les couleurs qui correspondent à votre charte graphique pour une expérience cohérente avec votre identité de marque. Cette option vous permet de proposer à vos clients une solution qui s’intègre parfaitement à vos process.`,
    },
    {
      question: "Aujourd'hui, je propose un lien vers un calendrier en ligne, puis-je conserver cette option?",
      answer: `Oui, avec notre formule premium, il vous est possible d'ajouter un lien vers votre calendrier en ligne à la fin du formulaire Discko.`,
    },
    {
      question: 'Comment puis-je intégrer Discko à mon site web ?',
      answer: `L'intégration de Discko sur votre site Internet est très simple et accessible à tous, même pour ceux qui n'ont pas de connaissances techniques avancées. Vous avez deux options : la première consiste à rediriger vos utilisateurs vers Discko au clic sur un bouton que vous implémentez sur votre site. La seconde option est d’intégrer directement Discko sur votre page via un iframe, ce qui permet une expérience utilisateur encore plus intégrée et sans interruption.`,
    },
    {
      question: 'Que se passe-t-il lorsque le nombre de crédits est épuisé ? ',
      answer: `Une fois tous vos crédits épuisés, vos prospects ne pourront plus utiliser Discko. Pour éviter toute interruption, nous vous recommandons de passer à notre offre mensuelle comprenant 250 crédits renouvelés chaque mois. `,
    },
    {
      question: 'Vos formules sont-elles sans engagement ?',
      answer:
        "Oui, toutes nos formules sont sans engagement. Vous pouvez choisir un tarif à l'utilisation. Si vous choisissez la formule abonnement, pouvez résilier votre abonnement à tout moment, sans frais supplémentaires.",
    },
    {
      question: 'Combien de personnes de l’équipe peuvent utiliser Discko ?',
      answer: `Selon la formule que vous choisissez, vous pouvez ajouter jusqu'à 10 membres de votre équipe à votre espace Discko.`,
    },
  ];
  return (
    <>
      {isLargeScreen ? (
        <div id="faq" className="w-full h-full flex flex-col gap-6 justify-center items-center relative px-10 py-20">
          <H2Title position="center" text="Questions fréquentes" color="black" />
          <div className="bg-white w-[70%] px-6">
            {faqData.map((item, index) => {
              return <FaqCollapse key={uuid()} id={index.toString()} title={item.question} content={item.answer} />;
            })}
          </div>
        </div>
      ) : (
        <div id="faq" className="w-full h-full flex flex-col gap-6 justify-center items-center relative  py-20">
          <H3Title position="center" text="Questions fréquentes" color="black" />
          <div className="bg-white px-2">
            {faqData.map((item, index) => {
              return <FaqCollapse key={uuid()} id={index.toString()} title={item.question} content={item.answer} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}
