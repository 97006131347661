import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Button } from 'components/Button/Button';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { H4Title } from 'components/H4Title';
import { P } from 'components/P';
import React from 'react';
import getIcon from 'utils/getIcon';
import { Audience, Language } from 'utils/languages';

export function DisckoTrialProcess(): JSX.Element {
  return (
    <div className="flex flex-col gap-10 items-center align-middle py-12">
      <H3Title text="Comment profiter de la version d’essai ?" color="black" position="center" />
      <div className="flex lg:flex-row flex-col items-center align-items justify-between gap-8 px-10">
        <Card width="md" height="md" background="white" textColor="black" horizontalPadding="lg" info="1">
          <div className="flex flex-col align-middle items-center gap-8 ">
            <div className="flex flex-row gap-4 items-center">
              <H4Title text="Inscrivez-vous"></H4Title>
            </div>
            <P
              position="justify"
              text="✍️ Complétez un formulaire rapide pour que notre équipe crée votre espace, c'est gratuit et sans engagement!"
            ></P>
          </div>
        </Card>
        <Card
          width="md"
          height="md"
          background="white"
          textColor="black"
          horizontalPadding="lg"
          verticalPadding="md"
          info="2"
        >
          <div className="flex flex-col align-middle  items-center gap-8">
            <div className="flex flex-row gap-4 items-center">
              <H4Title text="Connectez-vous"></H4Title>
            </div>
            <P
              position="justify"
              text="👉 Recevez vos identifiants de connexion pour commencer à utiliser Discko en toute simplicité"
            ></P>
          </div>
        </Card>
        <Card width="md" height="md" background="white" textColor="black" horizontalPadding="lg" info="3">
          <div className="flex flex-col align-middle items-center gap-8 ">
            <div className="flex flex-row gap-4 items-center">
              <H4Title text="Explorez 🚀"></H4Title>
            </div>
            <P
              position="justify"
              text="✨ Partagez Discko avec vos clients et obtenez des expressions de besoins complètes et synthétiques"
            ></P>
          </div>
        </Card>
      </div>
      <Button
        text="Commencer"
        backgroundColor="blue-400"
        href="/contact"
        size="h4"
        textColor="white"
        horizontalPadding="2"
      />
    </div>
  );
}
