import React from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { H3Title } from 'components/H3Title';
import { url } from 'inspector';
import { H4Title } from 'components/H4Title';

export interface VignetteLinkedin {
  source: string;
  sourceOnHover: string;
  url: string;
  isVignetteHovered: boolean;
  setIsVignetteHovered: (isVignetteHovered: boolean) => void;
}
export function Linkedin(): JSX.Element {
  const [isVignette1Hovered, setIsVignette1Hovered] = React.useState(false);
  const [isVignette2Hovered, setIsVignette2Hovered] = React.useState(false);
  const [isVignette3Hovered, setIsVignette3Hovered] = React.useState(false);
  const [isVignette4Hovered, setIsVignette4Hovered] = React.useState(false);

  const vignettesLinkedin: VignetteLinkedin[] = [
    {
      source: '/imgs/Lkdn_1.png',
      sourceOnHover: '/imgs/Lkdn_1_hover.png',
      url: 'https://www.linkedin.com/posts/discko_nous-avions-promis-de-partager-quelques-retours-activity-7216826024079040515-lNdq/?utm_source=share&utm_medium=member_desktop',
      isVignetteHovered: isVignette1Hovered,
      setIsVignetteHovered: setIsVignette1Hovered,
    },
    {
      source: '/imgs/Lkdn_2.png',
      sourceOnHover: '/imgs/Lkdn_2_hover.png',
      url: 'https://www.linkedin.com/posts/discko_ce-que-les-prospects-regardent-sur-le-site-activity-7218992815383478272-qCTo?utm_source=share&utm_medium=member_desktop',
      isVignetteHovered: isVignette2Hovered,
      setIsVignetteHovered: setIsVignette2Hovered,
    },
    {
      source: '/imgs/Lkdn_3.png',
      sourceOnHover: '/imgs/Lkdn_3_hover.png',
      url: 'https://www.linkedin.com/posts/discko_saviez-vous-que-seulement-13-des-clients-activity-7214656547803574274-E3XY?utm_source=share&utm_medium=member_desktop',
      isVignetteHovered: isVignette3Hovered,
      setIsVignetteHovered: setIsVignette3Hovered,
    },
    {
      source: '/imgs/Lkdn_4.png',
      sourceOnHover: '/imgs/Lkdn_4_hover.png',
      url: 'https://www.linkedin.com/posts/discko_8-conseils-pour-optimiser-votre-site-activity-7223696273122439170-guIs?utm_source=share&utm_medium=member_desktop',
      isVignetteHovered: isVignette4Hovered,
      setIsVignetteHovered: setIsVignette4Hovered,
    },
  ];
  return (
    <div className="bg-blue-100 flex flex-col lg:gap-12 gap-4 py-12 items-center px-4 lg:px-0">
      <div className="flex flex-col lg:flex-row lg:gap-8 gap-4 items-center">
        <H3Title text="Rejoignez notre communauté Linkedin" position="center" color="black" />
        <a
          className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}
          href="https://fr.linkedin.com/company/discko"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={concatClassNames('w-10 lg:w-16 h-10 lg:h-16 lg:mt-0')}
            src="/imgs/linkedin.png"
            alt="linkedin"
          />
        </a>
      </div>
      <H4Title
        text="Restez à jour sur les nouveautés Discko et découvrez ce qui fait la différence pour vos clients"
        position="center"
        color="black"
      />

      <div className="flex flex-row gap-32 px-24 justify-between">
        {vignettesLinkedin.map((vignetteLinkedin, index) => (
          <div
            key={index}
            onMouseEnter={() => {
              vignetteLinkedin.setIsVignetteHovered(!vignetteLinkedin.isVignetteHovered);
            }}
            onMouseLeave={() => {
              vignetteLinkedin.setIsVignetteHovered(!vignetteLinkedin.isVignetteHovered);
            }}
          >
            <a href={vignetteLinkedin.url} target="_blank" rel="noreferrer">
              <img
                className="w-full  rounded-lg"
                src={vignetteLinkedin.isVignetteHovered ? vignetteLinkedin.sourceOnHover : vignetteLinkedin.source}
                alt="linkedin"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
