/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Text } from 'components/Text/Text';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { Language } from 'utils/languages';
import { Button } from 'components/Button/Button';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { DisckoCTA } from 'components/Sections/Discko/DisckoCTA';
import { TextImage } from 'components/Sections/Discko/Text_Image';
import { RichText } from 'components/RichText/RichText';
import { AccompagnementSurMesure } from 'components/Sections/Discko/AccompagnementSurM';
import { BriefsComplets } from 'components/Sections/Discko/Briefs_complets';
import { Columns } from 'components/Sections/Discko/Columns';
import { CTA } from 'components/Sections/CTA';
import { Linkedin } from 'components/Sections/Discko/Linkedin';
import { Footer } from 'components/Sections/Footer';
import { Testimonies } from 'components/Sections/Testimonies';
import { QuestionnaireAdapte } from 'components/Sections/Discko/QuestionnaireAdapte';
import { H4Title } from 'components/H4Title';
import { SyntheseStructuree } from 'components/Sections/Discko/Synthese_structuree';

export function DisckoForClients(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const [isCard1Hovered, setIsCard1Hovered] = useState<boolean>(false);
  const [isCard2Hovered, setIsCard2Hovered] = useState<boolean>(false);
  const [isCard3Hovered, setIsCard3Hovered] = useState<boolean>(false);

  const textSection1: JSX.Element = (
    <RichText
      fragments={[
        {
          contentType: 'p',
          size: 'base',

          content:
            'Commencez par décrire votre projet en quelques mots. Discko vous guide ensuite avec des questions personnalisées qui évoluent en fonction de vos réponses, vous aidant à affiner et clarifier votre idée.',
        },
        { contentType: 'br' },

        {
          contentType: 'p',
          size: 'base',
          content:
            'Que vous ayez une vision claire de votre projet ou que vous soyez encore en phase d’exploration, Discko s’ajuste à vos besoins et vous guide avec des questions adaptées.',
        },
      ]}
    ></RichText>
  );

  const textSection2: JSX.Element = (
    <RichText
      fragments={[
        {
          contentType: 'p',
          size: 'base',
          content:
            'Une fois le questionnaire complété, Discko compile vos réponses pour générer une synthèse claire et standardisée, organisée en quatre sections : les objectifs de votre projet, les utilisateurs cibles, les facteurs clés de succès, et les contraintes (budgétaires, juridiques, etc.). ',
        },
        { contentType: 'br' },
        {
          contentType: 'p',
          size: 'base',
          content:
            "Cette approche vous offre une nouvelle perspective sur votre projet et vous aide à identifier des aspects que vous n'aviez peut-être pas envisagés. Vous obtenez ainsi une vision claire et précise de vos attentes, facilement partageable avec votre agence.",
        },
      ]}
    ></RichText>
  );

  const section3Column1: JSX.Element = (
    <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10">
      <img src="/imgs/tache.gif" className="h-24 w-24" />
      <div>
        <RichText
          fragments={[
            {
              contentType: 'p',
              size: 'h3title',
              content: 'Rédigez votre demande en toute simplicité',
              weight: 'bold',
            },
            { contentType: 'br' },
            {
              contentType: 'p',
              content:
                'Avec Discko, répondez simplement à quelques questions personnalisées sur votre projet, et l’outil se charge de synthétiser et structurer toutes les informations essentielles en un brief prêt à être partagé avec votre agence. Il servira de point de départ à vos discussions.',
            },
          ]}
        ></RichText>
      </div>
    </div>
  );

  const section3Column2: JSX.Element = (
    <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10">
      <img src="/imgs/conseil-strategique.gif" className="h-24 w-24" />
      <div>
        <RichText
          fragments={[
            {
              contentType: 'p',
              size: 'h3title',
              weight: 'bold',
              content: 'Arrivez préparés à vos rendez-vous',
              position: 'center',
            },
            { contentType: 'br' },

            {
              contentType: 'p',
              content:
                "Discko vous permet d'arriver aux rendez-vous avec une vision claire de vos attentes. Cela facilite les échanges avec l'agence, qui pourra ainsi vous proposer des solutions parfaitement adaptées à vos besoins.",
            },
          ]}
        ></RichText>
      </div>
    </div>
  );

  const section4Column1: JSX.Element = (
    <div className="flex flex-col gap-6">
      <P
        text="Que vous soyez en début de réflexion ou que vous ayez déjà une vision claire de votre projet, Discko est là pour vous aider à structurer et à communiquer efficacement vos idées à votre agence."
        position="justify"
        weight="normal"
      />
    </div>
  );
  return (
    <div className={concatClassNames('flex flex-col  bg-gradient-to-r from-gradient7-from to-gradient7-to lg:gap-12')}>
      <DisckoHeader language={Language.FR} />
      <div className="flex flex-col gap-6">
        <div className="py-12 flex flex-col items-center gap-12">
          <div className="h-40 w-40 rounded-full  bg-blue-200">
            <img src="/imgs/coeur.gif" className="h-full w-full rounded-full" />
          </div>
          <div className="px-12">
            <H1Title
              text="Exprimez votre projet avec clarté grâce à Discko"
              color="black"
              position="center"
              weight="bold"
            />
          </div>
          <div className="flex items-center align-middle justify-center gap-4 w-full px-20 ">
            <H4Title
              text="Vous avez une idée de projet, mais vous ne savez pas comment la formuler ? Discko est là pour vous aider à structurer vos pensées et à transmettre vos besoins de manière claire et concise à l'agence avec laquelle vous travaillez."
              color="black"
              position="justify"
              weight="medium"
            />
          </div>
        </div>
      </div>
      <DisckoCTA
        language={Language.FR}
        text="Tester Discko maintenant"
        button="Essayez gratuitement"
        textColor="black"
      />

      <QuestionnaireAdapte title="Un accompagnement sur-mesure" text={textSection1} />

      <SyntheseStructuree title="Une synthèse structurée" text={textSection2} />

      <Columns
        title="Gagnez du temps et de la clarté"
        nbColumns={2}
        column1={section3Column1}
        column2={section3Column2}
        padding="lg:px-24"
      />

      <Columns
        title="Discko, votre allié pour une expression de besoin réussie"
        nbColumns={1}
        column1={section4Column1}
        padding="lg:px-96 px-4"
      />

      <DisckoCTA
        language={Language.FR}
        text="Simplifiez vos échanges avec l’agence"
        button="Essayez gratuitement"
        textColor="black"
      />
      <Footer language={Language.FR} />
    </div>
  );
}
