import { Button } from 'components/Button/Button';
import { P } from 'components/P';
import React, { useState } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { Language } from 'utils/languages';
import { HashLink as Link } from 'react-router-hash-link';
import { HeaderMenu, type RedirectionProps } from 'components/HeaderMenu/HeaderMenu';
import uuid from 'react-uuid';
import { Bandeau } from '../Discko/Bandeau';
import { SmallP } from 'components/smallP';

export interface DisckoHeaderProps {
  language: Language;
}

interface MenuItem {
  name: string;
  href: string;
  imagesrc: string;
  weight?: string;
}

export interface MenuColumn {
  title?: string;
  items: MenuItem[];
}

const productMenuItems: MenuColumn[] = [
  {
    title: 'Une solution adaptée à vos besoins',
    items: [
      { name: 'Discko pour les agences', href: '/agences', imagesrc: '/imgs/creation-numerique.gif' },
      { name: 'Discko pour les clients', href: '/clients', imagesrc: '/imgs/response.gif' },
    ],
  },
];

const ressourcesMenuItem: MenuColumn[] = [
  {
    title: 'Aide',
    items: [
      { name: 'Contactez-nous', href: '/contact', imagesrc: '/imgs/dactylographie.gif' },
      { name: 'Questions fréquentes', href: '/#faq', imagesrc: '/imgs/question.gif' },
    ],
  },
  {
    title: 'Informations',
    items: [
      { name: 'Témoignages clients', href: '/#testimonies', imagesrc: '/imgs/coeur.gif' },
      { name: 'Demander une démo', href: '/contact', imagesrc: '/imgs/play.gif' },
      { name: 'Essayer maintenant', href: '/discko', imagesrc: '/imgs/disco-ball.gif', weight: 'bold' },
    ],
  },
];
const pricingMenuItem: MenuColumn[] = [
  {
    items: [{ name: 'Découvrez nos tarifs', href: '/pricing', imagesrc: '/imgs/price-tag.gif' }],
  },
];

const mobileRoutes: RedirectionProps[] = [
  { text: 'Produits', children: productMenuItems },
  { text: 'Ressources', children: ressourcesMenuItem },
  { text: 'Tarif', children: pricingMenuItem },
];

export function DisckoHeader({ language = Language.FR }: DisckoHeaderProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const [isProductMenuOpen, setIsProductMenuOpen] = useState<boolean>(false);
  const [isRessourcesMenuOpen, setIsRessourcesMenuOpen] = useState<boolean>(false);
  const [isProductHovered, setIsProductHovered] = useState<boolean>(false);
  const [isRessourcesHovered, setIsRessourcesHovered] = useState<boolean>(false);
  const [isPricingHovered, setIsPricingHovered] = useState<boolean>(false);
  const [isCTAHovered, setIsCTAHovered] = useState<boolean>(false);

  interface MenuItem {
    name: string;
    href: string;
    imagesrc: string;
  }

  interface MenuColumn {
    title: string;
    items: MenuItem[];
  }

  const productMenuItems: MenuColumn[] = [
    {
      title: 'Une solution adaptée à vos besoins',
      items: [
        { name: 'Discko pour les agences', href: '/agences', imagesrc: '/imgs/creation-numerique.gif' },
        { name: 'Discko pour les clients', href: '/clients', imagesrc: '/imgs/response.gif' },
      ],
    },
  ];

  return (
    <>
      {isLargeScreen ? (
        <>
          <div
            id="header"
            className={concatClassNames(
              'flex flex-row gap-12',
              'sticky top-0',
              'bg-white/30',
              'lg:bg-gradient-to-r lg:from-gradient7-from lg:to-gradient7-to',
              'lg:shadow-lg',
              'backdrop-blur-sm',
              'items-center',
              'justify-around',
              'py-2',
              'px-4',
              'lg:h-[90px]',
              'z-100',
              'relative',
            )}
            onMouseLeave={() => {
              setIsRessourcesHovered(false);
              setIsProductHovered(false);
              setIsProductMenuOpen(false);
              setIsRessourcesMenuOpen(false);
            }}
          >
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <img className={concatClassNames('h-16')} src="/imgs/logos/logoDisckoComplet.png" alt="Logo Discko" />
            </div>

            <div className={concatClassNames('hidden lg:flex flex-row', 'gap-12', 'hidden lg:block')}>
              <div id="productMenu" className={concatClassNames('hidden lg:flex flex-row', 'gap-20')}>
                <div
                  className="flex flex-row gap-2 items-center hover:cursor-pointer"
                  onClick={() => {
                    setIsProductMenuOpen(!isProductMenuOpen);
                  }}
                  onMouseEnter={() => {
                    setIsProductMenuOpen(true);
                    setIsRessourcesMenuOpen(false);
                    setIsRessourcesHovered(false);
                    setIsProductHovered(true);
                  }}
                >
                  <P text="Produit" font="font-calistoga" color={isProductHovered ? 'blue-400' : 'black'} />
                  {isProductHovered ? getIcon('chevronDown', 'cyan', 'sm') : getIcon('chevronDown', 'black', 'sm')}
                </div>
                <div
                  id="ressources_button"
                  className="flex flex-row gap-2 items-center hover:cursor-pointer"
                  onClick={() => {
                    setIsRessourcesMenuOpen(!isRessourcesMenuOpen);
                  }}
                  onMouseEnter={() => {
                    setIsRessourcesMenuOpen(true);
                    setIsProductMenuOpen(false);
                    setIsRessourcesHovered(true);
                    setIsProductHovered(false);
                  }}
                >
                  <P text="Ressources" font="font-calistoga" color={isRessourcesHovered ? 'blue-400' : 'black'} />
                  {isRessourcesHovered ? getIcon('chevronDown', 'cyan', 'sm') : getIcon('chevronDown', 'black', 'sm')}
                </div>
                <div
                  className="flex flex-row gap-2 items-center hover:cursor-pointer"
                  onClick={() => {
                    navigate('/pricing');
                  }}
                  onMouseEnter={() => {
                    setIsPricingHovered(true);
                    setIsRessourcesMenuOpen(false);
                    setIsProductMenuOpen(false);
                    setIsRessourcesHovered(false);
                    setIsProductHovered(false);
                  }}
                  onMouseLeave={() => {
                    setIsPricingHovered(false);
                  }}
                >
                  <P text="Tarif" font="font-calistoga" color={isPricingHovered ? 'blue-400' : 'black'} />
                </div>
                <div
                  className="flex flex-row gap-2 items-center hover:cursor-pointer"
                  onClick={() => {
                    navigate('/discko');
                  }}
                  onMouseEnter={() => {
                    setIsCTAHovered(!isCTAHovered);
                    setIsRessourcesMenuOpen(false);
                    setIsProductMenuOpen(false);
                  }}
                  onMouseLeave={() => {
                    setIsCTAHovered(!isCTAHovered);
                  }}
                >
                  <P
                    text="Essayez gratuitement"
                    font="font-calistoga"
                    position="left"
                    color={isCTAHovered ? 'blue-300' : 'blue-400'}
                  />
                </div>
              </div>
            </div>

            <div id="buttons" className=" hidden lg:flex flex-row gap-2 align-bottom items-end">
              <Button
                backgroundColor="white"
                textColor="blue-400"
                text="Accédez à votre espace"
                verticalPadding="1"
                horizontalPadding="2"
                href="https://app.discko.io"
                onHover="hover:bg-blue-300 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
              />
              <Button
                backgroundColor="blue-400"
                textColor="white"
                text="Contactez-nous"
                verticalPadding="1"
                horizontalPadding="2"
                href="/contact"
                onHover="hover:bg-blue-300 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
              />
            </div>

            {isProductMenuOpen && (
              <div
                id="products_dropdown"
                className={concatClassNames(
                  'flex flex-row',
                  // 'sticky top-0',
                  'bg-white/30',
                  'lg:bg-gradient-to-r lg:from-gradient7-from lg:to-gradient7-to',
                  // 'border-t-2 border-white',
                  'lg:shadow-lg',
                  'items-start',
                  'lg:h-[180px]',
                  'z-100',
                  'w-full',
                  'absolute top-20 left-0',
                  'pl-44',
                )}
              >
                <div className="grid-cols-2 left-[20%] flex flex-row w-full gap-2 py-4">
                  {productMenuItems.map((column, index) => (
                    <>
                      <div className="flex flex-col gap-2" key={index}>
                        <div className="text-xs font-bold uppercase flex w-full">{column.title}</div>
                        {column.items.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            className={concatClassNames(
                              'cursor-pointer text-md hover:text-blue-400',
                              'flex flex-row gap-2 items-center',
                              'w-full',
                            )}
                          >
                            <img src={item.imagesrc} alt={item.name} className="h-8 w-8 rounded-lg " />
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
                <div
                  className={concatClassNames(
                    'bg-blue-200 rounded-md flex flex-row w-[1000px] h-36 mr-24 cursor-pointer',
                    'hover:drop-shadow-lg',
                  )}
                  onClick={() => {
                    window.open(
                      'https://www.linkedin.com/posts/discko_8-conseils-pour-optimiser-votre-site-activity-7223696273122439170-guIs?utm_source=share&utm_medium=member_desktop',
                      '_blank',
                    );
                  }}
                >
                  <div
                    className={concatClassNames(
                      'flex flex-col gap-1',
                      'w-full',
                      'items-start align-middle justify-center',
                      'pr-24 pl-12',
                    )}
                  >
                    <SmallP
                      text="Ce que vos clients regardent sur votre site web"
                      position="left"
                      color="black"
                      weight="bold"
                    />
                    {/* <SmallP
                      text="Offrez une expérience personnalisée qui capte et engage vos prospects dès leur arrivée sur votre site."
                      position="left"
                      color="black"
                    /> */}
                    <SmallP text="Découvrez notre étude" position="left" color="black" style="underline" />
                  </div>
                  <div className={concatClassNames('flex w-[40%] items-center align-middle justify-center relative')}>
                    <img src="imgs/bandeau.png" alt="image" className="w-full" />
                  </div>
                </div>
              </div>
            )}
            {isRessourcesMenuOpen && (
              <div
                className={concatClassNames(
                  'flex flex-row',
                  // 'sticky top-0',
                  'bg-white/30',
                  'lg:bg-gradient-to-r lg:from-gradient7-from lg:to-gradient7-to',
                  'lg:shadow-lg',
                  'items-start',
                  'lg:h-[180px]',
                  'z-100',
                  'w-full',
                  'absolute top-20 left-0',
                  'pl-44',
                )}
              >
                <div className="grid-cols-2  flex flex-row w-full gap-12 py-4">
                  {ressourcesMenuItem.map((column, index) => (
                    <>
                      <div className="flex flex-col gap-2" key={index}>
                        <div className="text-xs font-bold uppercase flex w-full">{column.title}</div>
                        {column.items.map((item, index) => (
                          <Link
                            key={index}
                            to={item.href}
                            className={concatClassNames(
                              'cursor-pointer text-md hover:text-blue-400',
                              'flex flex-row gap-2 items-center',
                              'w-full',
                              item.weight === 'bold' ? 'font-semibold text-blue-400' : 'font-normal',
                            )}
                          >
                            <img src={item.imagesrc} alt={item.name} className="h-8 w-8 rounded-lg" />
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </>
                  ))}
                </div>

                <div
                  className={concatClassNames(
                    'bg-blue-200 rounded-md flex flex-row w-[1000px] h-36 mr-24 cursor-pointer',
                    'hover:drop-shadow-lg',
                  )}
                  onClick={() => {
                    window.open(
                      'https://www.linkedin.com/posts/discko_8-conseils-pour-optimiser-votre-site-activity-7223696273122439170-guIs?utm_source=share&utm_medium=member_desktop',
                      '_blank',
                    );
                  }}
                >
                  <div
                    className={concatClassNames(
                      'flex flex-col gap-1',
                      'w-full',
                      'items-start align-middle justify-center',
                      'pr-24 pl-12',
                    )}
                  >
                    <SmallP
                      text="Ce que vos clients regardent sur votre site web"
                      position="left"
                      color="black"
                      weight="bold"
                    />
                    {/* <SmallP
                      text="Offrez une expérience personnalisée qui capte et engage vos prospects dès leur arrivée sur votre site."
                      position="left"
                      color="black"
                    /> */}
                    <SmallP text="Découvrez notre étude" position="left" color="black" style="underline" />
                  </div>
                  <div className={concatClassNames('flex w-[40%] items-center align-middle justify-center relative')}>
                    <img src="imgs/bandeau.png" alt="image" className="w-full" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            id="header"
            className={concatClassNames(
              'flex flex-row',
              'sticky top-0',
              'bg-white/30',
              'lg:bg-gradient-to-r lg:from-gradient7-from lg:to-gradient7-to',
              'lg:shadow-lg',
              'backdrop-blur-sm',
              'items-center',
              'justify-between',
              'py-2',
              'px-4',
              'lg:h-[90px]',
              'z-100',
              'relative',
            )}
          >
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <img className={concatClassNames('h-16')} src="/imgs/logos/logoDisckoComplet.png" alt="Logo Discko" />
            </div>
            <HeaderMenu redirections={mobileRoutes} />
          </div>
        </>
      )}
    </>
  );
}
