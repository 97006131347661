import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type Audience, type Language } from 'utils/languages';

export function Video(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div className="flex flex-col w-full items-center gap-12 my-24">
          <video
            id="presentation"
            muted
            controls
            className="video w-full lg:w-[50%] shadow-xl"
            poster="/imgs/vignette_video.png"
          >
            <source src="/imgs/video_presentation.mp4" type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="flex flex-col w-full items-center gap-12 my-24">
          <video
            id="presentation"
            muted
            controls
            className="video w-full lg:w-[50%] shadow-xl"
            poster="/imgs/vignette_video.png"
          >
            <source src="/imgs/video_presentation.mp4" type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
}
