import { Button } from 'components/Button/Button';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type Audience, type Language } from 'utils/languages';

interface TrialProcessProps {
  language: Language;
  audience: Audience;
}

export function PricingSection(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div className="flex flex-col w-full items-center gap-12 my-24">
          <img src="/imgs/engagement.gif" className="h-[150px] w-[150px]" />

          <div className="relative items-center">
            <RichText
              fragments={[
                { contentType: 'span', content: 'Une offre', size: 'h2title', position: 'center' },
                {
                  contentType: 'span',
                  content: ' transparente et flexible',
                  size: 'h2title',
                  weight: 'bold',
                  position: 'center',
                },
              ]}
            />
            <img
              src="/imgs/surligneur_bleu.png"
              className={concatClassNames(
                'absolute opacity-50',
                'xl:-right-4 xl:-top-12 xl:rotate-3 xl:w-[540px]',
                'lg:-right-2 lg:-top-6 lg:rotate-6 lg:w-[320px] ',
                'right-0 top-8  rotate-2 w-[300px]',
              )}
            />
          </div>

          <div className="flex flex-col gap-12 w-full align-middle items-center justify-around">
            <div className="flex flex-col gap-8 mx-12 ">
              <P
                position="center"
                text="Ne vous souciez pas de votre consommation : intégrez Discko dans tous vos process de vente, pour une expérience utilisateur fluide et continue, et bénéficiez d'une personnalisation accrue."
              />
              <div className={concatClassNames('flex flex-col gap-4 items-center')}>
                <P
                  position="center"
                  text="Accédez à des expressions de besoin illimitées pour seulement 25€ par mois."
                  style="mt-4 font-circularStd-Medium"
                />
                <Button
                  href="/pricing"
                  text="Découvrez nos tarifs"
                  backgroundColor="blue-400"
                  textColor="white"
                  horizontalPadding="2"
                  verticalPadding="1"
                  size="p"
                  openInNewTab={false}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full items-center gap-12 my-24">
          <div className="relative items-center flex">
            <RichText
              fragments={[
                { contentType: 'p', content: 'Une offre', size: 'h2title', position: 'center' },
                {
                  contentType: 'span',
                  content: ' transparente et flexible',
                  size: 'h2title',
                  weight: 'bold',
                  position: 'center',
                },
              ]}
            />
            <img
              src="/imgs/surligneur_bleu.png"
              className={concatClassNames(
                'absolute opacity-50',
                'xl:right-2 xl:top-10 xl:rotate-2 xl:w-[560px]',
                'lg:-right-2 lg:-top-6 lg:rotate-6 lg:w-[320px] ',
                'right-0 top-8  rotate-2 w-[300px]',
              )}
            />
          </div>

          <div className="flex flex-col gap-12 w-full align-middle items-center justify-around">
            <div className="flex flex-col gap-8 mx-12 ">
              <P
                position="justify"
                text="Ne vous souciez plus de votre consommation : intégrez Discko dans tous vos process de vente, pour une expérience utilisateur fluide et continue, et bénéficiez d'une personnalisation accrue."
              />
              <div className={concatClassNames('flex gap-4 flex-col items-center')}>
                <P
                  position="center"
                  text="Accédez à des expressions de besoin illimitées pour seulement 25€ par mois."
                  style="mt-4 font-circularStd-Medium"
                />
                <Button
                  href="/pricing"
                  text="Découvrez nos tarifs"
                  backgroundColor="blue-400"
                  textColor="white"
                  horizontalPadding="2"
                  verticalPadding="1"
                  size="p"
                  openInNewTab={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
