import React, { type SetStateAction, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Squash as Hamburger } from 'hamburger-react';
import concatClassNames from 'utils/classNames';
import { type MenuColumn } from 'components/Sections/Header/DisckoHeader';
import ArrowUpFromSquareIcon from 'icons/IconsArrowUpFromSquare';
import uuid from 'react-uuid';
import getIcon from 'utils/getIcon';
import { get } from 'http';
import { boolean } from 'yup';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export interface RedirectionProps {
  text: string;
  href?: string;
  children?: MenuColumn[];
  linkIcon?: boolean;
}

interface HeaderMenuProps {
  redirections: RedirectionProps[];
}

export function HeaderMenu({ redirections }: HeaderMenuProps): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const navigate: NavigateFunction = useNavigate();

  useClickAway(ref, () => {
    setOpen(false);
  });

  function handleClickHAmburger(isOpen: SetStateAction<boolean>): void {
    setOpen(isOpen);
  }

  return (
    <div id="sub_header_mobile" className={concatClassNames('flex flex-row', 'gap-20')} ref={ref}>
      <Hamburger toggled={isOpen} size={20} toggle={handleClickHAmburger} color="black" />
      {isOpen && (
        <div
          id="global_mobile_dropdown"
          className={concatClassNames(
            'flex flex-col',
            'gap-6',
            'bg-white/30',
            'bg-gradient-to-r from-gradient7-from to-gradient7-to',
            'shadow-lg',
            'items-center',
            'z-100',
            'w-full',
            'absolute top-20 left-0',
            'px-2 py-3',
          )}
        >
          {redirections.map((route) => {
            return (
              <div key={uuid()} id={route.text} className="flex flex-col gap-2 items-center">
                <div
                  className={concatClassNames(
                    'flex gap-2',
                    'justify-center items-center',
                    'w-full',
                    'p-1 pl-3 pr-6',
                    'rounded-xl',
                  )}
                  onClick={() => {
                    if (route.href !== undefined) {
                      navigate(route.href);
                    }
                  }}
                >
                  <span className="flex gap-1 text-lg font-bold text-blue-400">{route.text}</span>
                </div>
                {route.children && (
                  <div className="flex flex-col gap-2">
                    {route.children.map((child) => {
                      return (
                        <div key={uuid()} className="flex flex-col gap-2">
                          <div className={concatClassNames('flex flex-col gap-2')}>
                            {child.items.map((item, index) => (
                              <Link
                                key={index}
                                to={item.href}
                                className={concatClassNames(
                                  'cursor-pointer text-md hover:text-blue-400',
                                  'flex flex-row gap-2 items-center',
                                  'w-full',
                                )}
                              >
                                <img src={item.imagesrc} alt={item.name} className="h-8 w-8 rounded-lg" />
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        // <div className="fixed right-3 top-[3.5rem] bg-white rounded-lg p-2 shadow-2xl">
        //   <ul className="grid gap-2">
        //     {redirections.map((route) => {
        //       return (
        //         <li key={route.text} className="w-full p-[0.08rem] rounded-xl">
        //           <a
        //             onClick={() => {
        //               setOpen(false);
        //             }}
        //             className={'flex gap-2 items-center w-full p-1 pl-3 pr-6 rounded-xl'}
        //             href={route.href}
        //           >
        //             <span className="flex gap-1 text-lg">{route.text}</span>
        //             {route.linkIcon && <ArrowUpFromSquareIcon color="black" size="sm" />}
        //           </a>
        //         </li>
        //       );
        //     })}
        //   </ul>
        // </div>
      )}
    </div>
  );
}
