import React, { useEffect, useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { H3Title } from 'components/H3Title';
import { useInView } from 'react-intersection-observer';

export interface Text_ImageProps {
  title: string;
  text: JSX.Element;
}

export function QuestionnaireAdapte({ title, text }: Text_ImageProps): JSX.Element {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0,
  });
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-col lg:flex-row', 'p-3 mx-10 py-12', 'gap-4')}>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full lg:px-24 ', 'items-center')}>
            <H3Title text={title} position="center" color="black" />
            <div className={concatClassNames('flex flex-col', 'p-3', 'gap-4 text-justify')}>{text}</div>
          </div>
          <div className={concatClassNames('flex items-center w-full justify-center')}>
            <div className="h-80 w-80 rounded-full bg-blue-200 relative">
              <div
                ref={ref1}
                className={concatClassNames(
                  'absolute top-0 -left-10 w-[280px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? '-translate-x-20' : 'opacity-0 translate-x-20 '
                  }`,
                )}
              >
                <img src="/imgs/Besoin_client.png" alt={title} />
              </div>
              <div
                ref={ref2}
                className={concatClassNames(
                  'absolute top-56 right-0 w-[420px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView2 ? 'translate-x-20' : 'opacity-0 -translate-x-20'
                  }`,
                )}
              >
                <img src="/imgs/question_5.png" alt={title} />
              </div>
              <div
                ref={ref3}
                className={concatClassNames(
                  'absolute top-28 right-0 w-[420px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView3 ? 'translate-x-20' : 'opacity-0 -translate-x-20 '
                  }`,
                )}
              >
                <img src="/imgs/question_4.png" alt={title} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={concatClassNames('flex flex-col lg:flex-row', 'p-3 mx-10 py-12', 'gap-4')}>
          <div className={concatClassNames('flex items-center align-middle justify-center w-full relative')}>
            <div className="h-60 w-60 rounded-full bg-blue-200 relative">
              <div
                ref={ref1}
                className={concatClassNames(
                  'absolute top-16 left-0 w-[200px] ',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? '-translate-y-10' : 'opacity-0 translate-y-10 '
                  }`,
                )}
              >
                <img src="/imgs/Besoin_client.png" alt={title} />
              </div>
              <div
                ref={ref2}
                className={concatClassNames(
                  'absolute top-24 left-10 w-[300px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? '-translate-x-10' : 'opacity-0 translate-x-10 '
                  }`,
                )}
              >
                <img src="/imgs/question_5.png" alt={title} />
              </div>
              <div
                ref={ref3}
                className={concatClassNames(
                  'absolute bottom-0 left-0 w-[280px]',
                  `transition-all ease-in-out duration-700 delay-300 ${
                    inView1 ? 'translate-x-10' : 'opacity-0 -translate-x-10 '
                  }`,
                )}
              >
                <img src="/imgs/question_4.png" alt={title} />
              </div>
            </div>
          </div>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full lg:px-24', 'items-center')}>
            <H3Title text={title} position="center" color="black" />
            <div className={concatClassNames('flex flex-col', 'p-3', 'gap-4 text-justify')}>{text}</div>
          </div>
        </div>
      )}
    </>
  );
}
