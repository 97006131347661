import React from 'react';

import concatClassNames from 'utils/classNames';

export type TextSize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'api'
  | 'base'
  | 'lg'
  | 'xl'
  | '1.5xl'
  | '2xl'
  | '2.5xl'
  | '3xl'
  | 'apiTitleChip'
  | 'h2title'
  | 'h3title';

export type TextWeight = 'light' | 'normal' | 'medium' | 'bold' | 'extrabold' | 'black';
export type LetterSpacing = 'tight' | 'normal' | 'wide';
export type TextPosition = 'left' | 'center' | 'right' | 'justify';
export type TextColor =
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'black'
  | 'pink'
  | 'blue'
  | 'blue-300'
  | 'transparent'
  | 'secondary'
  | 'blue-400'
  | 'yellow'
  | 'red-500'
  | 'red'
  | 'cyan'
  | 'green-500'
  | 'purple-400'
  | 'purple-500'
  | 'gradient2-from-light'
  | 'gradient2-from'
  | 'white';

export type TextBackgroundColor =
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'black'
  | 'pink'
  | 'blue'
  | 'yellow'
  | 'red-500'
  | 'red'
  | 'cyan'
  | 'green-500'
  | 'purple-400'
  | 'purple-500'
  | 'gradient2-from'
  | 'transparent'
  | 'bg-white/30'
  | 'white';

export type TextBackgroundRounded = 'none' | 'rounded-md' | 'rounded-lg' | 'rounded-xl' | 'rounded-2xl' | 'rounded-3xl';

export type TextBackgroundHorizontalPadding = 'none' | 'sm';

export type TextWhiteSpace = 'pre' | 'pre-line' | 'pre-wrap' | 'normal' | 'nowrap' | 'wrap' | 'wrap-reverse';
export type TextBreak = 'normal' | 'words' | 'all';

export const textSizeStyle: Record<TextSize, string> = {
  xxs: 'text-[9px]',
  xs: 'text-xs',
  sm: 'text-sm',
  api: 'text-api',
  base: 'text-[18px] lg:text-[18px] xl:text-[18px]',
  lg: 'text-lg',
  xl: 'text-xl',
  '1.5xl': 'text-[22px]',
  '2xl': 'text-xl lg:text-2xl',
  '2.5xl': 'text-[24px] lg:text-[27px]',
  '3xl': 'text-3xl',
  h2title: 'text-[30px] lg:text-[30px] xl:text-[45px]',
  h3title: 'text-[24px] lg:text-[24px] xl:text-[24px]',
  apiTitleChip: 'text-apiTitleChip',
};

export const textWeightStyle: Record<TextWeight, string> = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

export const letterSpacingStyle: Record<LetterSpacing, string> = {
  tight: 'tracking-tight',
  normal: 'tracking-normal',
  wide: 'tracking-wide',
};

// TODO : remove gray
export const textColorStyle: Record<TextColor, string> = {
  'gray-100': 'text-gray-100',
  'gray-200': 'text-gray-200',
  'gray-300': 'text-gray-300',
  'gray-400': 'text-gray-400',
  'gray-500': 'text-gray-500',
  'gray-600': 'text-gray-600',
  'gray-700': 'text-gray-700',
  transparent: 'text-transparent',

  black: 'text-black',
  pink: 'text-pink-600',
  blue: 'text-blue-800',
  'blue-300': 'text-blue-300',
  'blue-400': 'text-blue-400',
  yellow: 'text-yellow-400',
  'red-500': 'text-red-500',
  red: 'text-red-700',
  cyan: 'text-cyan-500',
  'green-500': 'text-green-500',
  'purple-400': 'text-purple-400',
  'purple-500': 'text-purple-500',
  'gradient2-from-light': 'text-[#cca0c8]',
  // rgb(228 181 223) tranform to hex
  'gradient2-from': 'text-gradient2-from',
  white: 'text-white',
  secondary: 'text-secondary-500',
};

export const textBackgroundColorStyle: Record<TextBackgroundColor, string> = {
  'gray-100': 'bg-gray-100',
  'gray-200': 'bg-gray-200',
  'gray-300': 'bg-gray-300',
  'gray-400': 'bg-gray-400',
  'gray-500': 'bg-gray-500',
  'gray-600': 'bg-gray-600',
  'gray-700': 'bg-gray-700',
  black: 'bg-black',
  pink: 'bg-pink-600',
  blue: 'bg-blue-800',
  yellow: 'bg-yellow-400',
  'red-500': 'bg-red-500',
  red: 'bg-red-700',
  cyan: 'bg-cyan-500',
  'green-500': 'bg-green-500',
  'purple-400': 'bg-purple-400',
  'purple-500': 'bg-purple-500',
  'gradient2-from': 'bg-gradient2-from',
  transparent: 'bg-transparent',
  'bg-white/30': 'bg-white/30',
  white: 'bg-white',
};

export const textBackgroundRoundedStyle: Record<TextBackgroundRounded, string> = {
  none: '',
  'rounded-md': 'rounded-md',
  'rounded-lg': 'rounded-lg',
  'rounded-xl': 'rounded-xl',
  'rounded-2xl': 'rounded-2xl',
  'rounded-3xl': 'rounded-3xl',
};

export const textBackgroundHorizontalPaddingStyle: Record<TextBackgroundHorizontalPadding, string> = {
  none: '',
  sm: 'px-1.5',
};

export const textPositionStyle: Record<TextPosition, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
};

export const textWhiteSpaceStyle: Record<TextWhiteSpace, string> = {
  pre: 'whitespace-pre',
  'pre-line': 'whitespace-pre-line',
  'pre-wrap': 'whitespace-pre-wrap',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
  wrap: 'whitespace-wrap',
  'wrap-reverse': 'whitespace-wrap-reverse',
};

export const textBreakStyle: Record<TextBreak, string> = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
};

export type TextDecoration = 'underline' | 'underline-4' | 'line-through' | 'no-underline';

export const textDecorationStyle: Record<TextDecoration, string> = {
  underline: 'underline',
  'underline-4': 'underline underline-offset-4',
  'line-through': 'line-through',
  'no-underline': 'no-underline',
};

export type TextMarginTop = 'none' | 'auto';

export const textMarginTop: Record<TextMarginTop, string> = {
  none: 'mt-0',
  auto: 'mt-auto',
};

export type TextMarginRight = 'none' | 'auto';

export const textMarginRight: Record<TextMarginRight, string> = {
  none: 'mr-0',
  auto: 'mr-auto',
};

export type TextMarginBottom = 'none' | 'auto';

export const textMarginBottom: Record<TextMarginBottom, string> = {
  none: 'mb-0',
  auto: 'mb-auto',
};

export type TextMarginLeft = 'none' | 'auto';

export const textMarginLeft: Record<TextMarginLeft, string> = {
  none: 'ml-0',
  auto: 'ml-auto',
};

export type textOverflow = 'none' | 'truncate' | 'ellipsis' | 'clip';

export const textOverflowStyle: Record<textOverflow, string> = {
  none: '',
  truncate: 'truncate',
  ellipsis: 'text-ellipsis overflow-hidden',
  clip: 'text-clip overflow-hidden',
};

export type TextProps =
  | {
      content: string;
      size?: TextSize;
      weight?: TextWeight;
      color?: TextColor;
      tracking?: LetterSpacing;
      position?: TextPosition;
      whitespace?: TextWhiteSpace;
      textBreak?: TextBreak;
      textDecoration?: TextDecoration;
      marginTop?: TextMarginTop;
      marginRight?: TextMarginRight;
      marginBottom?: TextMarginBottom;
      marginLeft?: TextMarginLeft;
      overFlow?: textOverflow;
      hidden?: boolean;
      loading?: false;
    }
  | {
      content?: never;
      size?: never;
      weight?: never;
      color?: never;
      tracking?: never;
      position?: never;
      whitespace?: never;
      textBreak?: never;
      textDecoration?: never;
      marginTop?: never;
      marginRight?: never;
      marginBottom?: never;
      marginLeft?: never;
      overFlow?: never;
      hidden?: boolean;
      loading: true;
    };

export function Text({
  content,
  color = 'black',
  size = 'base',
  weight = 'normal',
  tracking = 'normal',
  position = 'center',
  whitespace = 'pre-line',
  textBreak = 'normal',
  textDecoration = 'no-underline',
  marginTop = 'none',
  marginRight = 'none',
  marginBottom = 'none',
  marginLeft = 'none',
  overFlow = 'none',
  hidden = false,
  loading = false,
}: TextProps): JSX.Element {
  // TODO : Loading with the correct size
  if (loading) {
    return (
      <div className={concatClassNames('w-40', 'h-5', 'my-0.5', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>
    );
  } else {
    return (
      <p
        className={concatClassNames(
          textSizeStyle[size],
          textWeightStyle[weight],
          textColorStyle[color],
          letterSpacingStyle[tracking],
          textPositionStyle[position],
          textWhiteSpaceStyle[whitespace],
          textBreakStyle[textBreak],
          textDecorationStyle[textDecoration],
          textMarginTop[marginTop],
          textMarginRight[marginRight],
          textMarginBottom[marginBottom],
          textMarginLeft[marginLeft],
          textOverflowStyle[overFlow],
          'w-fit',
          'max-w-full',
        )}
        hidden={hidden}
      >
        {content}
      </p>
    );
  }
}
