import { Button } from 'components/Button/Button';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { H4Title } from 'components/H4Title';
import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useEffect, useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
export function DisckoProcess(): JSX.Element {
  const [isCard1Hovered, setIsCard1Hovered] = useState<boolean>(false);
  const [isCard2Hovered, setIsCard2Hovered] = useState<boolean>(false);
  const [isCard3Hovered, setIsCard3Hovered] = useState<boolean>(false);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div
          id="process"
          className="w-full h-full flex flex-col gap-4 justify-center items-center relative  xl:py-20 lg:py-12 bg-white"
        >
          <H3Title position="center" text="Choisissez la manière d’utiliser Discko selon vos besoins." color="black" />
          <div className="flex flex-row flex-grow gap-4 mx-8 h-full">
            {/* <div className={concatClassNames('flex text-[76px]')}>1</div> */}
            <img src="/imgs/line-chart.gif" className="h-[80px] w-[80px] " />
            <div
              className={concatClassNames(
                'rounded-lg w-full ',
                'flex flex-col lg:justify-start gap-6',
                'p-6',
                'text-justify',
                // 'hover:border-transparent hover:bg-blue-400  hover:text-white',
              )}
              onMouseEnter={() => {
                setIsCard1Hovered(true);
              }}
              onMouseLeave={() => {
                setIsCard1Hovered(false);
              }}
            >
              <H4Title
                position="left"
                color={isCard1Hovered ? 'black' : 'black'}
                text="Vous souhaitez optimiser l’acquisition via votre site ?"
                weight="bold"
              />
              <P
                position="justify"
                text="Insérez ce lien sur votre site Internet pour permettre à vos prospects de remplir le questionnaire en autonomie dès leur première visite."
                color={isCard1Hovered ? 'black' : 'black'}
              />

              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    size: 'base',
                    content: '👉 Faites en sorte qu’ils se sentent immédiatement ',
                    color: 'black',
                  },
                  {
                    contentType: 'span',
                    size: 'base',
                    content: 'compris et accompagnés ',
                    color: 'black',
                    weight: 'bold',
                  },
                  {
                    contentType: 'span',
                    size: 'base',
                    content: 'par votre agence, et ',
                    color: 'black',
                  },
                  {
                    contentType: 'span',
                    size: 'base',
                    content: 'gagnez leur confiance ',
                    color: 'black',
                    weight: 'bold',
                  },
                  { contentType: 'span', size: 'base', content: 'dès le premier contact.', color: 'black' },
                ]}
              ></RichText>
            </div>
            {/* <div className={concatClassNames('flex text-[76px]')}>2</div> */}
            <img src="/imgs/feedback-loop.gif" className="h-[80px] w-[80px]" />

            <div
              className={concatClassNames(
                'rounded-lg w-full  ',
                'flex flex-col lg:justify-start gap-6',
                'p-6',
                'text-justify',
                // 'hover:border-transparent hover:bg-blue-400 hover:text-white',
              )}
              onMouseEnter={() => {
                setIsCard2Hovered(true);
              }}
              onMouseLeave={() => {
                setIsCard2Hovered(false);
              }}
            >
              <H4Title
                position="left"
                text="Vous souhaitez gagner en temps et en qualité lors de vos premiers rendez-vous ?"
                color={isCard2Hovered ? 'black' : 'black'}
                weight="bold"
              />
              <P
                position="justify"
                color={isCard2Hovered ? 'black' : 'black'}
                text="Générez un lien unique à communiquer au client afin  qu'il puisse compléter le questionnaire à son propre rythme."
              />

              <RichText
                fragments={[
                  { contentType: 'span', size: 'base', content: '👉 Optimisez vos échanges avec des' },
                  {
                    contentType: 'span',
                    size: 'base',
                    content: ' clients préparés',
                    weight: 'bold',
                  },
                  { contentType: 'span', size: 'base', content: ' et' },
                  { contentType: 'span', size: 'base', content: ' personnalisez votre accompagnement' },
                  {
                    contentType: 'span',
                    size: 'base',
                    content: ' en fonction de leur maturité.',
                    weight: 'bold',
                  },
                ]}
              />
            </div>
            {/* <div className="flex flex-col"> */}
            {/* <div className={concatClassNames('flex text-[76px]')}>3</div> */}
            <img src="/imgs/engagement.gif" className="h-[80px] w-[80px]" />
            {/* </div> */}

            <div
              className={concatClassNames(
                'rounded-lg w-full',
                'flex flex-col lg:justify-start gap-6',
                'p-6',
                'text-justify',
                // 'hover:border-transparent hover:bg-blue-400 hover:text-white',
              )}
              onMouseEnter={() => {
                setIsCard3Hovered(true);
              }}
              onMouseLeave={() => {
                setIsCard3Hovered(false);
              }}
            >
              <H4Title
                position="left"
                color={isCard3Hovered ? 'black' : 'black'}
                text="Vous souhaitez dynamiser votre prospection ?"
                weight="bold"
              />
              <P
                position="justify"
                color={isCard3Hovered ? 'black' : 'black'}
                text="Intégrez Discko à vos campagnes d'e-mailing, AdWords ou LinkedIn pour capter l’attention de vos prospects dès le premier contact."
              />

              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    content: '👉 ',
                    size: 'base',
                  },
                  {
                    contentType: 'span',
                    content: "Boostez l'engagement",
                    size: 'base',
                    color: 'black',
                    weight: 'bold',
                  },
                  {
                    contentType: 'span',
                    content: ' et améliorez vos taux de réponse grâce à une approche',
                    size: 'base',
                    color: 'black',
                  },
                  {
                    contentType: 'span',
                    content: ' personnalisée et engageante.',
                    size: 'base',
                    color: 'black',
                    weight: 'bold',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          id="process"
          className="w-full h-full flex flex-col gap-12 justify-center items-center relative px-4 py-20 bg-white"
        >
          <H2Title position="center" text="Choisissez la manière d’utiliser Discko selon vos besoins." color="black" />
          <div className="flex flex-col gap-12 items-center h-full">
            <div className="flex flex-col items-center">
              <img src="/imgs/line-chart.gif" className="h-[80px] w-[80px] " />

              <div
                className={concatClassNames(
                  'rounded-lg w-full ',
                  'flex flex-col lg:justify-start gap-6',
                  'p-6',
                  'text-justify',
                )}
                onMouseEnter={() => {
                  setIsCard1Hovered(true);
                }}
                onMouseLeave={() => {
                  setIsCard1Hovered(false);
                }}
              >
                <H3Title
                  position="center"
                  color={isCard1Hovered ? 'black' : 'black'}
                  text="Vous souhaitez optimiser l’acquisition via votre site ?"
                  weight="bold"
                />
                <P
                  position="justify"
                  text="Insérez ce lien sur votre site Internet pour permettre à vos prospects de remplir le questionnaire en autonomie dès leur première visite."
                  color={isCard1Hovered ? 'black' : 'black'}
                />

                <RichText
                  fragments={[
                    {
                      contentType: 'span',
                      size: 'base',
                      content: '👉 Faites en sorte qu’ils se sentent immédiatement ',
                      color: 'black',
                    },
                    {
                      contentType: 'span',
                      size: 'base',
                      content: 'compris et accompagnés ',
                      color: 'black',
                      weight: 'bold',
                    },
                    {
                      contentType: 'span',
                      size: 'base',
                      content: 'par votre agence, et ',
                      color: 'black',
                    },
                    {
                      contentType: 'span',
                      size: 'base',
                      content: 'gagnez leur confiance ',
                      color: 'black',
                      weight: 'bold',
                    },
                    { contentType: 'span', size: 'base', content: 'dès le premier contact.', color: 'black' },
                  ]}
                ></RichText>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <img src="/imgs/feedback-loop.gif" className="h-[80px] w-[80px]" />

              <div
                className={concatClassNames(
                  'rounded-lg w-full  ',
                  'flex flex-col lg:justify-start gap-6',
                  'p-6',
                  'text-justify',
                )}
                onMouseEnter={() => {
                  setIsCard2Hovered(true);
                }}
                onMouseLeave={() => {
                  setIsCard2Hovered(false);
                }}
              >
                <H3Title
                  position="center"
                  text="Vous souhaitez gagner en temps et en qualité lors de vos premiers rendez-vous ?"
                  color={isCard2Hovered ? 'black' : 'black'}
                  weight="bold"
                />
                <P
                  position="justify"
                  color={isCard2Hovered ? 'black' : 'black'}
                  text="Générez un lien unique à communiquer au client afin  qu'il puisse compléter le questionnaire à son propre rythme."
                />

                <RichText
                  fragments={[
                    { contentType: 'span', size: 'base', content: '👉 Optimisez vos échanges avec des' },
                    {
                      contentType: 'span',
                      size: 'base',
                      content: ' clients préparés',
                      weight: 'bold',
                    },
                    { contentType: 'span', size: 'base', content: ' et' },
                    { contentType: 'span', size: 'base', content: ' personnalisez votre accompagnement' },
                    {
                      contentType: 'span',
                      size: 'base',
                      content: ' en fonction de leur maturité.',
                      weight: 'bold',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <img src="/imgs/engagement.gif" className="h-[80px] w-[80px]" />

              <div
                className={concatClassNames(
                  'rounded-lg w-full',
                  'flex flex-col lg:justify-start gap-6',
                  'p-6',
                  'text-justify',
                  // 'hover:border-transparent hover:bg-blue-400 hover:text-white',
                )}
                onMouseEnter={() => {
                  setIsCard3Hovered(true);
                }}
                onMouseLeave={() => {
                  setIsCard3Hovered(false);
                }}
              >
                <H3Title
                  position="center"
                  color={isCard3Hovered ? 'black' : 'black'}
                  text="Vous souhaitez dynamiser votre prospection ?"
                  weight="bold"
                />
                <P
                  position="justify"
                  color={isCard3Hovered ? 'black' : 'black'}
                  text="Intégrez Discko à vos campagnes d'e-mailing, AdWords ou LinkedIn pour capter l’attention de vos prospects dès le premier contact."
                />

                <RichText
                  fragments={[
                    {
                      contentType: 'span',
                      content: '👉 ',
                      size: 'base',
                    },
                    {
                      contentType: 'span',
                      content: "Boostez l'engagement",
                      size: 'base',
                      color: 'black',
                      weight: 'bold',
                    },
                    {
                      contentType: 'span',
                      content: ' et améliorez vos taux de réponse grâce à une approche',
                      size: 'base',
                      color: 'black',
                    },
                    {
                      contentType: 'span',
                      content: ' personnalisée et engageante.',
                      size: 'base',
                      color: 'black',
                      weight: 'bold',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
