/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Text } from 'components/Text/Text';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { Language } from 'utils/languages';
import { Button } from 'components/Button/Button';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { DisckoCTA } from 'components/Sections/Discko/DisckoCTA';
import { TextImage } from 'components/Sections/Discko/Text_Image';
import { RichText } from 'components/RichText/RichText';
import { AccompagnementSurMesure } from 'components/Sections/Discko/AccompagnementSurM';
import { BriefsComplets } from 'components/Sections/Discko/Briefs_complets';
import { Columns } from 'components/Sections/Discko/Columns';
import { CTA } from 'components/Sections/CTA';
import { Linkedin } from 'components/Sections/Discko/Linkedin';
import { Footer } from 'components/Sections/Footer';
import { Testimonies } from 'components/Sections/Testimonies';
import { H4Title } from 'components/H4Title';

export function DisckoForAgencies(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const [isCard1Hovered, setIsCard1Hovered] = useState<boolean>(false);
  const [isCard2Hovered, setIsCard2Hovered] = useState<boolean>(false);
  const [isCard3Hovered, setIsCard3Hovered] = useState<boolean>(false);

  interface NumberedStatement {
    percent: string;
    statement: string;
  }
  const numberedStatements: NumberedStatement[] = [
    {
      percent: '8 personnes sur 10',

      statement: "ayant testé Discko se disent plus enclines à choisir une agence qui l'utilise",
    },
    {
      percent: '100% des clients ',
      statement:
        "déclarent se sentir plus engagés avec l'agence lorsqu'ils utilisent Discko pour présenter leurs projets",
    },
    {
      percent: '96% des clients',
      statement: 'trouvent les questions et les suggestions pertinentes par rapport à leur projet',
    },
  ];

  const textSection1: JSX.Element = (
    <RichText
      fragments={[
        {
          contentType: 'p',
          size: 'base',

          content:
            'Il n’est pas toujours facile pour vos clients de mettre des mots sur leurs idées et de clarifier leurs besoins.',
        },
        { contentType: 'br' },

        {
          contentType: 'p',
          size: 'base',
          content: 'Discko simplifie ce processus en guidant vos clients à travers des questions personnalisées.',
        },
        { contentType: 'br' },
        {
          contentType: 'p',
          size: 'base',
          content:
            'En tant qu’agence, vous pouvez personnaliser les questions pour mieux comprendre les besoins de vos clients.',
        },
        { contentType: 'br' },
        {
          contentType: 'span',
          size: 'base',
          content:
            '👉 Discko s’adapte parfaitement à l’univers, au niveau de maturité, et aux spécificités du projet de chaque client, assurant ainsi une ',
        },
        {
          contentType: 'span',
          weight: 'bold',
          size: 'base',
          content: 'expression des besoins claire, précise et alignée avec leurs attentes.',
        },
      ]}
    ></RichText>
  );

  const textSection2: JSX.Element = (
    <RichText
      fragments={[
        {
          contentType: 'p',
          size: 'base',
          content: 'Fatigué des briefs incomplets ou des cahiers des charges interminables ? ',
        },
        { contentType: 'br' },
        {
          contentType: 'p',
          size: 'base',
          content:
            'Discko vous permet de recevoir une synthèse claire, concise et parfaitement structurée des besoins de vos clients.',
        },
        { contentType: 'br' },

        {
          contentType: 'span',
          weight: 'bold',

          size: 'base',
          content: '👉 Obtenez rapidement toutes les informations essentielles ',
        },
        {
          contentType: 'span',
          size: 'base',
          content:
            ': objectifs, utilisateurs cibles, contraintes, et facteurs clés de succès, le tout réuni dans un document synthétique qui facilite la préparation et l’exécution de votre projet.',
        },
      ]}
    ></RichText>
  );

  const section3Column1: JSX.Element = (
    <div className="flex flex-col lg:gap-10 gap-12">
      <div className="lg:h-[120px]">
        <RichText
          fragments={[
            {
              contentType: 'span',
              size: 'base',
              content:
                'Discko transforme l’expression des besoins de vos clients en un résumé clair et structuré, leur permettant de bénéficier de votre expertise avant même de vous rencontrer. Cette approche personnalisée leur donne ',
            },
            {
              contentType: 'span',
              size: 'base',
              content: "l'impression d'être parfaitement compris, renforçant ainsi leur confiance ",
              weight: 'bold',
            },
            { contentType: 'span', size: 'base', content: 'en votre agence.' },
          ]}
        ></RichText>
      </div>

      <div className="flex flex-row gap-4">
        {/* <img src="/imgs/response.gif" className="h-20 w-20" /> */}
        <H4Title
          text="✨ Augmentez de manière significative votre taux de conversion et la satisfaction client."
          position="justify"
          weight="bold"
        />
      </div>
    </div>
  );

  const section3Column2: JSX.Element = (
    <div className="flex flex-col lg:gap-10 gap-12">
      <div className="lg:h-[120px]">
        <RichText
          fragments={[
            {
              contentType: 'span',
              size: 'base',
              content:
                'Discko pose des questions pertinentes qui incitent vos clients à réfléchir aux aspects clés de leur projet avant même de vous rencontrer. Ce processus ',
            },
            { contentType: 'span', size: 'base', content: 'prépare vos clients, ', weight: 'bold' },
            { contentType: 'span', size: 'base', content: 'vous garantissant un ' },
            { contentType: 'span', size: 'base', content: 'premier rendez-vous plus productif.', weight: 'bold' },
          ]}
        ></RichText>
      </div>
      <div className="flex flex-row gap-4">
        {/* <img src="/imgs/response.gif" className="h-20 w-20" /> */}
        <H4Title
          text="✨ Réduisez la durée de vos premières réunions tout en augmentant la qualité de vos échanges."
          position="justify"
        />
      </div>
    </div>
  );

  const section4Column1: JSX.Element = (
    <div className="flex flex-col gap-6">
      <P
        text="Adoptez Discko et montrez à vos clients que votre agence est à la pointe de l’innovation. Grâce à l’intelligence artificielle, anticipez leurs besoins et offrez une expérience personnalisée dès le premier contact."
        position="justify"
        weight="normal"
      />

      <P
        text="Discko ne remplace pas l’humain. Il vient compléter le processus d'exploration en amont en facilitant la préparation et en optimisant les échanges, tout en laissant place aux discussions approfondies et au dialogue humain."
        position="justify"
        weight="normal"
      />
      <br />
      <H4Title
        text="73% des personnes qui ont testé Discko estiment que l'agence qui l’utilise est une agence innovante"
        position="center"
      />
    </div>
  );
  return (
    <div className={concatClassNames('flex flex-col  bg-gradient-to-r from-gradient7-from to-gradient7-to lg:gap-12')}>
      <DisckoHeader language={Language.FR} />
      <div className="flex flex-col gap-6">
        <div className="py-12 flex flex-col items-center gap-12">
          <div className="h-40 w-40 rounded-full  bg-blue-200">
            <img src="/imgs/passion.gif" className="h-full w-full rounded-full" />
          </div>
          <div className="px-12">
            <H1Title
              text="Transformez le premier contact avec vos clients"
              color="black"
              position="center"
              weight="bold"
            />
          </div>
        </div>
        <div className="items-center justify-center lg:gap-4 lg:grid lg:grid-cols-3 flex flex-col gap-6">
          {numberedStatements.map((numberedStatement, index) => (
            <div key={index} className="px-12">
              <H3Title text={numberedStatement.percent} color="blue-400" position="center" />
              <P text={numberedStatement.statement} color="black" position="center" />
            </div>
          ))}
        </div>
      </div>
      <DisckoCTA
        language={Language.FR}
        text="Démarquez-vous avec Discko"
        button="Essayez gratuitement"
        textColor="black"
      />

      <AccompagnementSurMesure title="Un accompagnement sur-mesure" text={textSection1} />

      <BriefsComplets title="Des briefs complets et synthétiques" text={textSection2} />

      <div id="conversion_client">
        <Columns
          title="Démarquez-vous et gagnez en efficacité dès les premiers rendez-vous"
          nbColumns={2}
          column1={section3Column1}
          column2={section3Column2}
        />
      </div>
      <br />
      <div id="intelligence_artificielle">
        <Columns
          title="Montrez votre caractère innovant"
          subtitle="L'IA au service de votre agence"
          nbColumns={1}
          column1={section4Column1}
          padding="lg:px-96 px-4"
        />
      </div>

      <DisckoCTA
        language={Language.FR}
        text="Boostez votre agence avec l’IA"
        button="Essayez gratuitement"
        textColor="black"
      />
      <Linkedin />
      <Footer language={Language.FR} />
    </div>
  );
}
