import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import concatClassNames from 'utils/classNames';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export function DisckoIsNot(): JSX.Element {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0,
  });

  return (
    <div
      className={concatClassNames(
        'flex flex-col lg:gap-10 gap-20 items-center w-full px-10 lg:my-0 py-20 overflow-hidden',
        'bg-blue-400',
      )}
    >
      <div
        className={concatClassNames(
          'flex flex-col items-center',
          ' lg:w-[50%] ',
          ' lg:px-10',
          `transition-all ease-in-out duration-700 ${
            inView1 ? 'lg:-translate-x-60' : 'opacity-0 -translate-x-20 lg:translate-x-0 '
          }`,
        )}
      >
        <div ref={ref1} className="w-full">
          <H2Title color="white" position="center" text="Discko, ce n'est pas..." />
        </div>
        <div className={concatClassNames('flex flex-col items-center gap-4 w-[100%]')}>
          <H3Title color="white" position="center" text="un substitut aux réunions de cadrage" strikethrough></H3Title>

          <P
            color="white"
            position="justify"
            text="Notre objectif n'est pas de se substituer au travail essentiel de votre équipe. Nous sommes convaincus que la véritable compréhension d'un projet vient des interactions humaines. Les discussions approfondies, l'observation directe et les échanges restent irremplaçables. Discko est là pour soutenir et enrichir votre processus, jamais pour s'y substituer."
          />
        </div>
      </div>
      <div
        className={concatClassNames(
          'flex flex-col items-center',
          'lg:w-[50%]',
          'lg:px-10',
          `transition-all ease-in-out duration-700 ${
            inView2 ? 'lg:translate-x-60' : 'opacity-0 translate-x-20 lg:translate-x-0 '
          }`,
        )}
      >
        <div ref={ref2} className="w-full">
          <H2Title color="white" position="center" text="Discko, c'est..." />
        </div>
        <div className={concatClassNames('flex flex-col items-center gap-4 w-[100%]')}>
          <H3Title color="white" position="center" text="un outil d'aide à l'expression du besoin "></H3Title>
          <P
            position="justify"
            color="white"
            text="Discko est conçu pour transformer le premier contact avec vos clients. Il assure que chacun d'entre vous arrive mieux préparé à votre première réunion afin d’optimiser votre processus de vente. Discko vous aide à identifier le niveau de maturité de vos clients par rapport à leur projet et à personnaliser votre accompagnement."
          ></P>
        </div>
      </div>
    </div>
  );
}
