import { Button } from 'components/Button/Button';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';

export interface DisckoOneLinerProps {
  language: Language;
}
export function DisckoOneLiner({ language = Language.FR }: DisckoOneLinerProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <div id="eye_catcher" className={concatClassNames('flex flex-row ')}>
      <div
        className={concatClassNames(
          'flex flex-col',
          'items-start',
          'gap-10',
          'xl:m-16',
          'py-20 px-10 z-10 w-full h-full',
        )}
      >
        <div>
          <H1Title color="blue-400" position="left" text="Offrez-vous des clients" weight="bold" />
          <H1Title color="blue-400" position="left" text="qui savent ce qu'ils veulent." weight="bold" />
        </div>

        <div className={concatClassNames('flex flex-row justify-start')}>
          <>
            <Button
              backgroundColor="blue-400"
              textColor="white"
              text="Essayez gratuitement"
              size="p"
              verticalPadding="1"
              horizontalPadding="2"
              href="/discko"
              onHover="hover:bg-blue-300 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
            />
          </>
        </div>
      </div>
    </div>
  );
}
