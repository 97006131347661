import React from 'react';

import { TermsWidget } from './TermsWidget';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { Footer } from 'components/Sections/Footer';
import { Language } from 'utils/languages';
import concatClassNames from 'utils/classNames';

export function TermsPage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 lg:gap-4')}
    >
      <DisckoHeader language={Language.FR} />
      <TermsWidget />
      <Footer language={Language.FR} />
    </div>
  );
}
