import React from 'react';

interface MessageQuoteProps {
  isLargeScreen?: boolean;
}

export function MessageQuoteIcon({ isLargeScreen = false }: MessageQuoteProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={isLargeScreen ? '70' : '40'}
      width={isLargeScreen ? '70' : '40'}
      viewBox="0 0 512 512"
      // className="animate-bounce"
    >
      <path
        fill="#60A5FA"
        d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm160 48c-17.7 0-32 14.3-32 32v48c0 17.7 14.3 32 32 32h32v7.3c0 11.7-8.5 21.7-20.1 23.7l-7.9 1.3c-13.1 2.2-21.9 14.5-19.7 27.6s14.5 21.9 27.6 19.7l7.9-1.3c34.7-5.8 60.2-35.8 60.2-71V192 168 144c0-17.7-14.3-32-32-32H160zm224 80V168 144c0-17.7-14.3-32-32-32H304c-17.7 0-32 14.3-32 32v48c0 17.7 14.3 32 32 32h32v7.3c0 11.7-8.5 21.7-20.1 23.7l-7.9 1.3c-13.1 2.2-21.9 14.5-19.7 27.6s14.5 21.9 27.6 19.7l7.9-1.3c34.7-5.8 60.2-35.8 60.2-71V192z"
      />
    </svg>
  );
}
