import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Language } from 'utils/languages';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { PreInscriptionForm } from 'components/Sections/PreInscriptionForm';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { TestSection } from 'components/Sections/TestSection';
import { P } from 'components/P';
import { NumberedStatement } from 'components/Sections/NumberedStatement';
import { DisckoNumberedStatement } from 'components/Sections/Discko/DisckoNumberedStatement';
import { DisckoCTA } from 'components/Sections/Discko/DisckoCTA';
import { Bandeau } from 'components/Sections/Discko/Bandeau';
import { H4Title } from 'components/H4Title';
import { DisckoTrialProcess } from 'components/Sections/Discko/DisckoTrialProcess';
import { Testimonies } from 'components/Sections/Testimonies';
import { DisckoTabs } from 'components/Sections/Discko/DisckoTabs';

const language: Language = Language.FR;

export function DisckoTestPage(): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 lg:gap-12 w-full',
      )}
    >
      <DisckoHeader language={language} />
      <div className="flex flex-col w-full gap-12 items-center">
        <H1Title position="center" text="Essayez Discko gratuitement" weight="bold" />
        <H4Title
          position="center"
          text="Découvrez dès à présent comment Discko peut vous aider à gagner en efficacité dès les premiers rendez-vous."
        />
        <TestSection />
      </div>
      <DisckoNumberedStatement
        language={language}
        title="Améliorez vos taux de conversion et votre ROI."
        options={[
          'Optimisez le premier contact avec vos clients pour augmenter vos taux de conversion',
          'Préparez vos clients en amont pour des rendez-vous plus efficaces et productifs',
          'Recevez des briefs complets et précis pour simplifier la qualification de vos prospects',
        ]}
      />
      <DisckoTrialProcess />
      <DisckoTabs />
      <Testimonies />
      <Bandeau
        title="Votre site web est votre premier commercial"
        subtitle="Avec Discko, offrez une expérience personnalisée qui capte et engage vos prospects dès leur arrivée sur votre site."
        // CTA1={{ text: 'Essayez gratuitement', href: '/discko' }}
        CTA2={{ text: 'Nous contacter', href: '/contact' }}
        imageSrc="/imgs/bandeau.png"
        gifSrc="/imgs/engagement_transparent.gif"
      />
      <Footer language={language} />
    </div>
  );
}
