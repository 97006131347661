import React, { useState } from 'react';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';

export interface BandeauProps {
  title: string;
  subtitle?: string;
  CTA1?: { text: string; href: string };
  CTA2?: { text: string; href: string };
  imageSrc?: string;
  gifSrc?: string;
}

export function Bandeau({ title, subtitle, CTA1, CTA2, imageSrc, gifSrc }: BandeauProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-row bg-blue-400 p-12')}>
          <div
            className={concatClassNames(
              'flex flex-col gap-6',
              'w-full',
              'items-start align-middle justify-center',
              'pr-24 pl-12',
            )}
          >
            <H2Title text={title} position="left" color="white" />
            {subtitle ? <H3Title text={subtitle} position="left" color="white" /> : null}
            <div className={concatClassNames('flex flex-row justify-center gap-4')}>
              {CTA1 && (
                <Button
                  text={CTA1.text}
                  backgroundColor="white"
                  href={CTA1.href}
                  horizontalPadding="2"
                  size="p"
                  textColor="blue-400"
                />
              )}
              {CTA2 && (
                <Button
                  text={CTA2.text}
                  backgroundColor="white"
                  href={CTA2.href}
                  horizontalPadding="2"
                  size="p"
                  textColor="blue-400"
                />
              )}
            </div>
          </div>
          <div className={concatClassNames('flew w-[40%] items-center align-middle justify-center relative')}>
            <img src={imageSrc} alt="image" className="" />
          </div>
        </div>
      ) : (
        <div className={concatClassNames('flex flex-row bg-blue-400 p-12')}>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full', 'items-start align-middle justify-center')}>
            <H2Title text={title} position="left" color="white" />
            {subtitle ? <H3Title text={subtitle} position="left" color="white" /> : null}
            <div className={concatClassNames('flex flex-row w-full justify-start  gap-4')}>
              {CTA1 && (
                <Button
                  text={CTA1.text}
                  backgroundColor="white"
                  href={CTA1.href}
                  horizontalPadding="2"
                  size="p"
                  textColor="blue-400"
                />
              )}
              {CTA2 && (
                <Button
                  text={CTA2.text}
                  backgroundColor="white"
                  href={CTA2.href}
                  horizontalPadding="2"
                  size="h4"
                  textColor="blue-400"
                />
              )}
            </div>
          </div>
          {imageSrc && (
            <div className={concatClassNames('flew w-[40%] items-center align-middle justify-center relative')}>
              <img src={imageSrc} alt="image" className="" />
            </div>
          )}
        </div>
      )}
    </>
  );
}
