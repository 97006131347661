import React from 'react';

import concatClassNames from 'utils/classNames';

import {
  type CardProps,
  cardBackgroundColorStyle,
  cardBorderColorStyle,
  cardDeepnessStyle,
  cardHeightStyle,
  cardHorizontalMarginStyle,
  cardHorizontalPaddingStyle,
  cardOrientationStyle,
  cardRoundStyle,
  cardShadowStyle,
  cardVerticalMarginStyle,
  cardVerticalPaddingStyle,
  cardWidthStyle,
} from './Card.props';
import { textColorStyle } from '../Text/Text';

export function Card({
  width,
  height,
  shadow = 'none',
  background = 'white',
  borderColor = 'lightGray',
  children,
  onClick,
  loading,
  customRef,
  orientation = 'vertical',
  verticalPadding = 'none',
  horizontalPadding = 'none',
  vertialMargin = 'none',
  horizontalMargin = 'none',
  round = '3xl',
  deepness = 'none',
  hasAnimation = false,
  textColor = 'black',
  position = 'justify-center',
  info,
}: CardProps): JSX.Element {
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  // TODO : remove the 'xxx !== undefined'
  return (
    <div className="flex flex-col items-center ">
      <div className="rounded-t-md bg-blue-400 text-white text-base items-center w-fit px-6 py-1">{info}</div>
      <div
        onClick={onClick}
        className={concatClassNames(
          'flex flex-col',
          cardOrientationStyle[orientation],
          'items-center',
          position,
          cardVerticalPaddingStyle[verticalPadding],
          cardHorizontalPaddingStyle[horizontalPadding],
          cardRoundStyle[round],
          'border-1',
          cardBorderColorStyle[borderColor],
          textColorStyle[textColor],
          cardShadowStyle[shadow],
          width !== undefined ? cardWidthStyle[width] : '',
          height !== undefined ? cardHeightStyle[height] : '',
          cardBackgroundColorStyle[background],
          cardVerticalMarginStyle[vertialMargin],
          cardHorizontalMarginStyle[horizontalMargin],
          cardDeepnessStyle[deepness],
          hasAnimation ? 'hover:-translate-y-3 duration-700 ease-in-out' : '',
        )}
        ref={customRef}
      >
        {children}
      </div>
    </div>
  );
}
