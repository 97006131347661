import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { H3Title } from 'components/H3Title';
import { H4Title } from 'components/H4Title';

export interface Text_ImageProps {
  title: string;
  subtitle?: string;
  nbColumns: number;
  column1: JSX.Element;
  column2?: JSX.Element;
  column3?: JSX.Element;
  padding?: string;
}

export function Columns({
  title,
  subtitle,
  nbColumns = 1,
  column1,
  column2,
  column3,
  padding = 'px-12',
}: Text_ImageProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <>
      {isLargeScreen ? (
        <div className={concatClassNames('flex flex-col ', 'w-full', 'lg:p-3 py-12', 'gap-12')}>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full lg:px-12', 'items-center')}>
            <H3Title text={title} position="center" color="black" />
            {subtitle ? <H4Title text={subtitle} position="center" color="black" /> : null}
          </div>
          <div
            className={concatClassNames(
              `flex flex-col lg:grid lg:grid-cols-${nbColumns}`,
              'w-full text-justify',
              'justify-evenly',
              'p-3',
              'gap-4',
            )}
          >
            <div className={concatClassNames('flex flex-col', 'w-full px-12', padding, 'items-center')}>{column1}</div>
            {column2 && (
              <div className={concatClassNames('flex flex-col', 'w-full px-16', padding, 'items-center')}>
                {column2}
              </div>
            )}
            {column3 && (
              <div className={concatClassNames('flex flex-col', 'w-full px-16', padding, 'items-center')}>
                {column3}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={concatClassNames('flex flex-col ', 'w-full', 'lg:p-3 py-12', 'gap-12')}>
          <div className={concatClassNames('flex flex-col gap-6', 'w-full px-4', 'items-center')}>
            <H3Title text={title} position="center" color="black" />
            {subtitle ? <H4Title text={subtitle} position="center" color="black" /> : null}
          </div>
          <div
            className={concatClassNames(
              `flex flex-col lg:grid lg:grid-cols-${nbColumns}`,
              'w-full text-justify',
              'justify-evenly',
              'p-3',
              'gap-4',
            )}
          >
            <div className={concatClassNames('flex flex-col', 'w-full px-12', padding, 'items-center')}>{column1}</div>
            {column2 && (
              <div className={concatClassNames('flex flex-col', 'w-full px-12', padding, 'items-center')}>
                {column2}
              </div>
            )}
            {column3 && (
              <div className={concatClassNames('flex flex-col', 'w-full px-12', padding, 'items-center')}>
                {column3}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
