import React, { useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { VideoPeople } from 'components/VideoPeople/VideoPeople';
import { MessageQuoteIcon } from 'icons/MessageQuote';
import 'swiper/css';
import 'swiper/css/pagination';
import { type Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import uuid from 'react-uuid';

interface SlideProps {
  name: string;
  quote: string;
  linkedin: string;
  job: string;
  company: string;
  namePath?: string;
}

function Slide({ name, quote, linkedin, job, company, namePath = name }: SlideProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <>
      {isLargeScreen ? (
        <div
          className={concatClassNames(
            'flex flex-col lg:grid lg:grid-cols-1',
            'min-h-[250px] h-full',
            'p-3 px-10',
            'bg-white',
            'rounded-md',
            'flex-grow',
            'justify-between items-center',
            'w-fit',
          )}
        >
          <div
            className={concatClassNames(
              'flex flex-row h-full py-2 items-center gap-6 justify-center flex-grow cursor-move',
            )}
          >
            <div className="w-[15%]">
              <MessageQuoteIcon isLargeScreen={isLargeScreen} />
            </div>
            <div className="flex flex-col gap-2 items-center">
              <P color="black" position="center" text={quote} weight="normal" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={concatClassNames(
            'flex flex-col lg:grid lg:grid-cols-1',
            'min-h-[300px] h-full',
            'p-3 px-6',
            'bg-white',
            'rounded-md',
            'flex-grow',
            'justify-between items-center',
            'w-fit',
          )}
        >
          <div
            className={concatClassNames(
              'flex flex-col h-[300px] py-2 items-center gap-6 justify-center flex-grow cursor-move',
            )}
          >
            <div className="w-[15%]">
              <MessageQuoteIcon isLargeScreen={isLargeScreen} />
            </div>
            <div className="flex flex-col gap-2 items-center">
              <P color="black" position="center" text={quote} />
              {/* <a
            className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}
            href={linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <img className={concatClassNames('w-5 h-5')} src="/imgs/linkedin.png" alt="linkedin" />
            <span className={concatClassNames('text-center text-gray-400 lg:text-lg')}>{name}</span>
          </a> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export interface RollsProps {
  language: Language;
}

interface Testimony {
  name: string;
  quote: string;
  linkedin: string;
  job: string;
  company: string;
  favFeature: string;
}

export function Testimonies(): JSX.Element {
  const sliderRef: React.MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const testimonies: Testimony[] = [
    {
      name: 'Myriam P.',
      quote:
        'La solution a compris ce que je voulais alors que moi-même, je ne savais pas comment présenter mon projet',
      linkedin: '',
      job: '',
      company: '',
      favFeature: '',
    },
    {
      name: 'Marine M.',
      quote:
        "Les réponses que je donne sont reprises de manière claire et précise. ça m'aide à mieux présenter mon projet. Franchement, bravo!",
      linkedin: '',
      job: '',
      company: '',
      favFeature: '',
    },
    {
      name: 'Iyad C.',
      quote:
        "L'agence parait vraiment moderne et pro. Je me sens en confiance, rassuré par le fait que l'équipe est efficace, qu'elle saura m'accompagner pour exprimer mon besoin et réaliser mon projet.",
      linkedin: '',
      job: '',
      company: '',
      favFeature: '',
    },
    {
      name: 'David M.',
      quote:
        "Les questions évoluent en fonction de mes réponses, c'est super interactif. Je me sens compris et ça me donne envie d'en dire plus sur mon projet",
      linkedin: '',
      job: '',
      company: '',
      favFeature: '',
    },
  ];

  return (
    <>
      {isLargeScreen ? (
        <>
          <div id="testimonies" className={concatClassNames('flex flex-col gap-12 py-4 text-white pt-20')}>
            <H2Title text="Ce que vos clients en pensent" color="black" position="center" />

            <div className={concatClassNames('overflow-hidden')}>
              <Swiper
                slidesPerView={2}
                loop={true}
                centeredSlides={true}
                spaceBetween={160}
                style={{
                  '--swiper-pagination-color': '#fff',
                }}
                modules={[Pagination]}
                className="flex h-fit align-middle justify-center items-center overflow-hidden"
                wrapperClass="flex flex-row mb-14"
                onSwiper={(it: SwiperType) => (sliderRef.current = it)}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                pagination={{
                  type: 'bullets',
                  clickable: true,
                  enabled: true,
                  bulletClass: 'bg-white opacity-50 hover:opacity-100',
                  bulletActiveClass: 'bg-white opacity-100',
                }}
              >
                {testimonies.map((item, index) => (
                  <SwiperSlide key={uuid()}>
                    <Slide
                      name={item.name}
                      quote={item.quote}
                      linkedin={item.linkedin}
                      job={item.job}
                      company={item.company}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="testimonies" className={concatClassNames('flex flex-col gap-12 py-4 text-white pt-20')}>
            <H3Title text="Ce que vos clients en pensent" color="black" position="center" />

            <div className={concatClassNames('overflow-hidden')}>
              <Swiper
                slidesPerView={3}
                loop={true}
                centeredSlides={true}
                spaceBetween={30}
                style={{
                  '--swiper-pagination-color': '#60A5FA',
                }}
                modules={[Pagination, Autoplay]}
                className="flex h-fit align-middle justify-center items-center w-[250%] -left-[75%] overflow-hidden"
                wrapperClass="flex flex-row mb-14"
                onSwiper={(it: SwiperType) => (sliderRef.current = it)}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                autoplay={{
                  delay: 4000,
                }}
                pagination={{
                  type: 'bullets',
                  clickable: true,
                  enabled: true,
                  bulletClass: 'bg-white opacity-50 hover:opacity-100',
                  bulletActiveClass: 'bg-white opacity-100',
                }}
              >
                {testimonies.map((item, index) => (
                  <SwiperSlide key={uuid()}>
                    <Slide
                      name={item.name}
                      quote={item.quote}
                      linkedin={item.linkedin}
                      job={item.job}
                      company={item.company}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      )}
    </>
  );
}
