import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { H4Title } from 'components/H4Title';
import { P } from 'components/P';
import { animate, motion, useInView, useMotionValue, useSpring, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { optionCSS } from 'react-select/dist/declarations/src/components/Option';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';
import { Video } from '../Video';

interface DisckoCounterProps {
  to: number;
}

function DisckoCounter({ to }: DisckoCounterProps): JSX.Element {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const count = useMotionValue(0);
  const rounded = useTransform(count, (x) => Math.round(x * 10) / 10);

  useEffect(() => {
    if (isInView) {
      const animation = animate(count, to, {
        duration: 1.5,
      });

      return animation.stop;
    }
  }, [isInView]);

  return (
    <>
      <span className={concatClassNames('p-1.5', 'pt-3')}>
        <motion.span className={concatClassNames('text-3xl lg:text-4xl', 'font-circularStd-Bold')} ref={ref}>
          {rounded}
        </motion.span>
        <span className={concatClassNames('text-base', 'font-circularStd-Bold')}>%</span>
      </span>
    </>
  );
}
export interface DisckoNumberedStatementProps {
  language: Language;
  title: string;
  options: string[];
}
export function DisckoNumberedStatement({
  language = Language.FR,
  title,
  options,
}: DisckoNumberedStatementProps): JSX.Element {
  return (
    <div
      id="stats"
      className={concatClassNames(
        'lg:relative',
        'flex flex-col items-center justify-center',
        'gap-3',
        'mt-12',
        'gap-4',
        'pt-11 pb-11 px-10',
        'lg:pt-20 lg:pb-20 px-10',
        'bg-blue-400',
      )}
    >
      <H2Title position="center" color="white" text={title}></H2Title>
      <div className="flex flex-col items-start gap-2 ">
        <br />
        {options.map((option, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            <H4Title position="left" color="white" text="✅  "></H4Title>
            <H4Title color="white" position="left" text={option} weight="medium"></H4Title>
          </div>
        ))}
      </div>
    </div>
  );
}
