import React, { Fragment, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToAnchor(): JSX.Element {
  const location = useLocation();
  const lastHash = useRef('');

  async function sleep(ms: number, func: any): Promise<void> {
    const toto: any = await new Promise((resolve) => setTimeout(resolve, ms));
    func();
    return toto;
  }

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
        void sleep(1000, () => {
          window.scrollBy({ top: -100, behavior: 'auto' });
        });
      }, 100);
    }
  }, [location]);

  return <></>;
}

export function ScrollToTop(): JSX.Element {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
}
