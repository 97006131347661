import FileCheckIcon from 'icons/FileCheckIcon';
import { type IconSize, type IconColor } from 'icons/Icon.props';
import RocketIcon from 'icons/RocketIcon';
import TickIcon from 'icons/Tick';
import UserIcon from 'icons/UserIcon';
import { UserIcon as RealUserIcon } from 'icons/User';
import React from 'react';
import PlusIcon from 'icons/PlusIcon';
import ChevronDownIcon from 'icons/ChevronDownIcon';

export type IconName = 'fileCheck' | 'user' | 'rocket' | 'tick' | 'realUser' | 'plus' | 'chevronDown';

export default function getIcon(name: IconName, color: IconColor, size: IconSize, strokeWidth = 2): JSX.Element {
  switch (name) {
    case 'fileCheck':
      return <FileCheckIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'chevronDown':
      return <ChevronDownIcon color={color} size={size} />;
    case 'user':
      return <UserIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'rocket':
      return <RocketIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'tick':
      return <TickIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'realUser':
      return <RealUserIcon color={color} size={size} strokeWidth={strokeWidth} />;
    case 'plus':
      return <PlusIcon color={color} size={size} strokeWidth={strokeWidth} />;
    default:
      return <div>error</div>;
  }
}

export interface IconProps {
  color: IconColor;
  size: IconSize;
  strokeWidth?: number;
}
