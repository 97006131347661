/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useRef, useState } from 'react';
import { SliderPartners } from 'components/SliderPartners/SliderPartners';

import 'swiper/css';
import 'swiper/css/navigation';
import { Testimonies } from 'components/Sections/Testimonies';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Language } from 'utils/languages';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { DisckoOneLiner } from 'components/Sections/Discko/DisckoOneliner';
import { DisckoNumberedStatement } from 'components/Sections/Discko/DisckoNumberedStatement';
import { DisckoCTA } from 'components/Sections/Discko/DisckoCTA';
import { DisckoProcess } from 'components/Sections/Discko/DisckoProcess';
import { DisckoIsNot } from 'components/Sections/Discko/DisckoIsNot';
import { DisckoTabs } from 'components/Sections/Discko/DisckoTabs';
import { DisckoFaq } from 'components/Sections/Discko/Discko FAQ';
import { PricingSection } from 'components/Sections/PricingSection';
import { DisckoDescription } from 'components/Sections/Discko/DisckoDescription';
import { Linkedin } from 'components/Sections/Discko/Linkedin';
import { Bandeau } from 'components/Sections/Discko/Bandeau';
import { HomePagePicture } from 'components/Sections/Discko/HomePageImage';
import { Video } from 'components/Sections/Video';

const language: Language = Language.FR;

export function DisckoHomePage(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    document.title = 'Discko';
  }, []);
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 lg:gap-4')}
    >
      <DisckoHeader language={language} />

      <div
        className={concatClassNames('flex flex-col lg:flex-row w-full h-full lg:pt-10 lg:justify-start', ' relative')}
      >
        <DisckoOneLiner language={language} />
        {isLargeScreen && <HomePagePicture />}
      </div>

      {/* <Video /> */}
      <DisckoNumberedStatement
        language={language}
        title="90% des prospects décident de continuer ou non une collaboration après le premier contact."
        options={[
          'Transformez le premier contact avec vos clients',
          'Obtenez des briefs complets & synthétiques',
          'Augmentez vos taux de conversion',
        ]}
      />
      <Video />

      <DisckoDescription />
      <DisckoCTA language={language} text="Prenez l'innovation en marche" button="Essayez gratuitement" />

      <DisckoProcess />
      {!isLargeScreen && <DisckoIsNot />}
      <DisckoTabs />

      {isLargeScreen && <DisckoIsNot />}

      <PricingSection />
      <Bandeau
        title="Prenez l'innovation en marche"
        CTA1={{ text: 'Essayez gratuitement', href: '/discko' }}
        imageSrc="/imgs/bandeau.png"
        gifSrc="/imgs/engagement_transparent.gif"
      />
      <Testimonies />
      <DisckoFaq />
      {isLargeScreen && <Linkedin />}
      <Footer language={language} textColor="black" discko />
    </div>
  );
}
