import React, { useState } from 'react';
import * as Yup from 'yup';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const language: Language = Language.FR;

interface InscriptionFormModel {
  name: string;
  firstName: string;
  email: string;
  company: string;
  phone: string;
}

const schema: Yup.ObjectSchema<InscriptionFormModel> = Yup.object().shape({
  name: Yup.string().required('Ce champ est requis'),
  firstName: Yup.string().required('Ce champ est requis'),
  email: Yup.string().email('Ce champ doit être un email').required('Ce champ est requis'),
  company: Yup.string().required('Ce champ est requis'),
  phone: Yup.string().required('Ce champ est requis'),
});

interface PreInscriptionFormProps {
  submitedMessage: JSX.Element;
  type: 'discko' | 'specks';
}

export function PreInscriptionForm({ submitedMessage, type }: PreInscriptionFormProps): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  async function onSubmitForm(data: InscriptionFormModel): Promise<void> {
    setIsSubmitted(true);

    let response: Response;

    const myHeaders: Headers = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw: string = JSON.stringify({
      entreprise: data.company,
      nom: data.name,
      prenom: data.firstName,
      email: data.email,
      numero: data.phone,
      type,
    });

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    try {
      response = await fetch('https://uhzfpex3x7qk32dfev4bmp27ay0hshkk.lambda-url.eu-west-3.on.aws/', requestOptions);
    } catch (error) {
      console.log('catch error', error);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InscriptionFormModel>({
    resolver: yupResolver(schema),
  });

  if (isSubmitted) {
    return <div className="flex flex-col justify-center ali  gn-middle items-center h-full">{submitedMessage}</div>;
  }
  return (
    <form
      className="flex flex-col gap-10 items-center w-[100%] justify-center font-circularStd-Regular text-[18px] lg:text-[18px] lg:px-8 lg:mt-0"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className={concatClassNames('flex flex-col gap-4 w-full items-center  text-black')}>
        <input
          {...register('name')}
          className={concatClassNames(
            'rounded-full p-4',
            'w-[80%] lg:w-[100%]',
            errors.name !== undefined ? 'border-4 border-red-500' : '',
          )}
          type="text"
          placeholder="Nom*"
          id="name"
        />
        {errors.name && <p className="text-white text-sm"> {errors.name.message}</p>}

        <input
          {...register('firstName')}
          className={concatClassNames(
            'rounded-full p-4',
            'w-[80%] lg:w-[100%]',
            errors.firstName !== undefined ? 'border-4 border-red-500' : '',
          )}
          type="text"
          placeholder="Prénom*"
          id="firstname"
        />
        {errors.firstName && <p className="text-white text-sm"> {errors.firstName.message}</p>}
        <input
          {...register('email')}
          className={concatClassNames(
            'rounded-full p-4',
            'w-[80%] lg:w-[100%]',
            errors.email !== undefined ? 'border-4 border-red-500' : '',
          )}
          type="text"
          placeholder="Email*"
          id="email"
        />
        {errors.email && <p className="text-white text-sm"> {errors.email.message}</p>}
        <input
          {...register('phone')}
          className={concatClassNames(
            'rounded-full p-4',
            'w-[80%] lg:w-[100%]',
            errors.phone !== undefined ? 'border-4 border-red-500' : '',
          )}
          type="text"
          placeholder="Téléphone*"
          id="phone"
        />
        {errors.phone && <p className="text-white text-sm">{errors.phone.message}</p>}
        <input
          {...register('company')}
          className={concatClassNames(
            'rounded-full p-4',
            'w-[80%] lg:w-[100%]',
            errors.company !== undefined ? 'border-4 border-red-500' : '',
          )}
          type="text"
          placeholder="Société*"
          id="company"
        />
        {errors.company && <p className="text-white text-sm"> {errors.company.message}</p>}
      </div>
      <button
        type="submit"
        className={concatClassNames(
          'w-fit h-fit rounded-full px-6 py-1',
          'hover:bg-blue-200 bg-blue-300 text-white',

          'border-1 border-transparent',
          'hover:text-white',
        )}
      >
        {'Valider'}
      </button>
    </form>
  );
}
